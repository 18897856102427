import { createStyles, makeStyles } from "@material-ui/core/styles";
import { FONT } from "../../../utils/font";
import COLOR from "../../../utils/color";

const useStyles = makeStyles(() =>
  createStyles({
    buttonContainer: {
      justifyContent: "center",
      padding: "8px 0",
    },
    subtitle: {
      ...FONT.SMALL,
      color: COLOR.GREY_LIGHT_4,
    },
    row: {
      paddingBottom: 20,
    },
    rowsContainer: {
      padding: 10,
    },
    text: {
      ...FONT.PARAGRAPH,
      fontWeight: 600,
      color: COLOR.GREY,
      paddingTop: 16,
    },
  })
);

export default useStyles;
