import { summariesSliceActions } from "./reducer";
import {
  SummariesListError,
  SummariesListLoading,
  Summary,
  SummaryId,
  SummaryPayload,
  SummaryResponse,
  SummaryResult,
  SummaryUpdate,
} from "./types";
import { RootState } from "../types";
import { PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { summariesServices } from "./services";
import { convertSummaryFromResponse } from "../../app/utils/functions";
import { Ordering } from "../../app/components/table/types";
import { FiltersState } from "../filters/types";

const getSummaryList = (
  page: number,
  records: number,
  ordering: Ordering,
  filters?: FiltersState
): ThunkAction<
  void,
  RootState,
  unknown,
  PayloadAction<SummaryPayload | SummariesListError | SummariesListLoading>
> => {
  return (dispatch) => {
    dispatch(summariesSliceActions.setSummariesListLoading(true));
    summariesServices
      .getSummariesList(page, records, ordering, filters)
      .then((data) => data?.data)
      .then((response: SummaryResponse) => {
        const results: SummaryResult[] = response.results;
        const list: Summary[] =
          results &&
          results.map((item) => {
            const summary = convertSummaryFromResponse(item);

            return summary;
          });

        dispatch(
          summariesSliceActions.setSummariesList({
            count: response.count,
            summaries: list,
          })
        );
      })
      .catch((err) => {
        if (err.response) {
          console.error(err.response);
        } else if (err.request) {
          console.error(err.request);
        } else {
          console.error(`Error: ${err.message}`);
        }
        console.error(err.config);
        dispatch(summariesSliceActions.setSummariesListError(err.message));
      });
  };
};

const removeSummary = (
  summaryId: SummaryId
): ThunkAction<
  void,
  RootState,
  unknown,
  PayloadAction<SummaryPayload | SummariesListError | SummariesListLoading>
> => {
  return (dispatch, getState) => {
    dispatch(summariesSliceActions.setSummariesListLoading(true));
    summariesServices
      .deleteSummary(summaryId)
      .then(() => {
        const list = getState().summaries.list;
        const newList = list?.filter((summary) => summary.id !== summaryId);

        newList &&
          dispatch(
            summariesSliceActions.setSummariesList({
              count: getState().summaries.count,
              summaries: newList,
            })
          );
      })
      .catch((err) => {
        if (err.response) {
          console.error(err.response);
        } else if (err.request) {
          console.error(err.request);
        } else {
          console.error(`Error: ${err.message}`);
        }
        console.error(err.config);
        dispatch(summariesSliceActions.setSummariesListError(err.message));
      });
  };
};

const updateSummary = (
  summaryId: SummaryId,
  summary: SummaryUpdate
): ThunkAction<
  void,
  RootState,
  unknown,
  PayloadAction<SummaryPayload | SummariesListError | SummariesListLoading>
> => {
  return (dispatch, getState) => {
    dispatch(summariesSliceActions.setSummariesListLoading(true));
    summariesServices
      .updateSummary(summaryId, summary)
      .then((data) => {
        const item = data.data;
        const list = getState().summaries.list;
        const newList =
          list &&
          list.map((summary) => {
            if (summary.id === summaryId) {
              return convertSummaryFromResponse(item);
            } else {
              return summary;
            }
          });

        newList &&
          dispatch(
            summariesSliceActions.setSummariesList({
              count: getState().summaries.count,
              summaries: newList,
            })
          );
      })
      .catch((err) => {
        if (err.response) {
          console.error(err.response);
        } else if (err.request) {
          console.error(err.request);
        } else {
          console.error(`Error: ${err.message}`);
        }
        console.error(err.config);
        dispatch(summariesSliceActions.setSummariesListError(err.message));
      });
  };
};

const summariesActions = {
  ...summariesSliceActions,
  getSummaryList,
  removeSummary,
  updateSummary,
};

export default summariesActions;
