import { AxiosPromise } from "axios";
import { SummaryId, SummaryResponse, SummaryUpdate } from "./types";
import { SUMMARIES_URLS } from "./constants";
import axiosInstance from "../axiosInstance";
import { Ordering } from "../../app/components/table/types";
import { createURL } from "../utils";
import { FiltersState } from "../filters/types";

const getSummariesList = (
  page: number,
  records: number,
  ordering: Ordering,
  filters?: FiltersState
): AxiosPromise<SummaryResponse> => {
  const url = createURL({
    base: SUMMARIES_URLS.summariesListUrl,
    page: page,
    records: records,
    ordering: ordering,
    filters: filters,
  });

  return axiosInstance({
    url,
    method: "GET",
  });
};

const deleteSummary = (summaryId: SummaryId): AxiosPromise => {
  const url = `${SUMMARIES_URLS.deleteSummaryUrl}${summaryId}`;

  return axiosInstance({
    url,
    method: "DELETE",
  });
};

const updateSummary = (
  summaryId: SummaryId,
  summary: SummaryUpdate
): AxiosPromise => {
  const url = `${SUMMARIES_URLS.updateSummaryUrl}${summaryId}`;

  return axiosInstance({
    url,
    method: "PUT",
    data: summary,
  });
};

export const summariesServices = {
  deleteSummary,
  getSummariesList,
  updateSummary,
};
