export enum COOKIE {
  TOKEN = "token",
}

export enum OG_TYPE {
  BRAND = "brand",
  TRANSPARENT = "transparent",
}

export enum BUTTON_TYPE {
  BUTTON = "button",
  RESET = "reset",
  SUBMIT = "submit",
}

export enum VARIANT {
  OUTLINED = "outlined",
  TEXT = "text",
  CONTAINED = "contained",
}

export enum ICON_SIZE {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export enum INPUT_TYPE {
  CHECKBOX = "checkbox",
  DATE = "date",
  EMAIL = "email",
  MULTI_TEXT = "multiText",
  PASSWORD = "password",
  SELECT = "select",
  TEXT = "text",
}

export enum INPUT_VARIANT {
  FILLED = "filled",
  OUTLINED = "outlined",
  STANDARD = "standard",
}

export enum DATE_VARIANT {
  INLINE = "inline",
}

export enum POSITION_TYPE {
  ABSOLUTE = "absolute",
  FIXED = "fixed",
  RELATIVE = "relative",
  STATIC = "static",
}

export enum SEVERITY {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
}

export type ButtonType =
  | BUTTON_TYPE.BUTTON
  | BUTTON_TYPE.RESET
  | BUTTON_TYPE.SUBMIT;

export type ButtonVariant = VARIANT.OUTLINED | VARIANT.TEXT | VARIANT.CONTAINED;

export type IconSize = ICON_SIZE.MEDIUM | ICON_SIZE.SMALL;

export type InputType =
  | INPUT_TYPE.EMAIL
  | INPUT_TYPE.PASSWORD
  | INPUT_TYPE.TEXT;

export type InputVariant =
  | INPUT_VARIANT.FILLED
  | INPUT_VARIANT.OUTLINED
  | INPUT_VARIANT.STANDARD;

export type PositionType =
  | POSITION_TYPE.ABSOLUTE
  | POSITION_TYPE.FIXED
  | POSITION_TYPE.RELATIVE
  | POSITION_TYPE.STATIC;

export type OgType = OG_TYPE.BRAND | OG_TYPE.TRANSPARENT;

export interface SchemaProps {
  [key: string]: string;
}

export type Text = Record<string, string>;

export interface SelectItem {
  text: string;
  value: number;
}

export type SelectItemsList = SelectItem[];

export interface BaseItemDetails {
  id?: number;
  name?: string;
}
