import { createStyles, makeStyles } from "@material-ui/core/styles";
import { FONT } from "../../../utils/font";
import COLOR from "../../../utils/color";

export const useStyles = makeStyles(() => {
  return createStyles({
    brand: {
      background: COLOR.YELLOW,
      color: COLOR.BLACK,

      "&:hover": {
        background: COLOR.YELLOW,

        "& img": {
          transition: "transform 0.3s ease",
          transform: "rotate(0deg)",
        },
      },
    },
    logo: {
      height: 34,
      transition: "transform 0.3s ease",
      transform: "rotate(-45deg)",
      width: 34,
    },
    logoWrapper: {
      borderLeft: `2px solid ${COLOR.BLACK}`,
      padding: 23,
    },
    transparent: {
      background: COLOR.TRANSPARENT,
    },

    root: {
      padding: 0,

      "&:hover": {},

      "& .MuiButton-label": {
        display: "flex",
      },
    },
    text: {
      ...FONT.MEDIUM,
      flex: 1,
      textTransform: "none",
      padding: 26,
    },
  });
});
