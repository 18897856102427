import { CircularProgress } from "@material-ui/core";
import useStyles from "./styles";

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress color="inherit" />
    </div>
  );
};

export default Loader;
