import Cookies from "universal-cookie";
import { COOKIE } from "./types";

export const getCookie = (cookie: COOKIE): string | undefined => {
  const cookies = new Cookies();

  return cookies.get(cookie);
};

export const setCookie = (cookie: COOKIE, value: string, path = "/") => {
  const cookies = new Cookies();

  cookies.set(cookie, value, { path });
};

export const removeCookie = (cookie: COOKIE, path = "/") => {
  const cookies = new Cookies();

  cookies.remove(cookie, { path });
};
