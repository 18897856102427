import { Project } from "../../redux/projects/types";
import { User } from "../../redux/users/types";
import {
  Summary,
  SUMMARY_RESPONSE,
  SummaryResult,
} from "../../redux/summaries/types";
import { BaseItemDetails } from "./types";

export const convertDataForSelect = (list: (Project | User)[]) => {
  return list.map((item) => ({ text: item?.name || "-", value: item.id || 0 }));
};

export const replaceSpaceToDash = (text: string) => {
  return text.replace(" ", "-").toLowerCase();
};

export const getItemsIdSearchedByName = (
  list: BaseItemDetails[],
  searchText: string
) => {
  return list?.reduce<(number | undefined)[]>(
    (ids: (number | undefined)[], item: User | Project) => {
      const text = searchText?.toLowerCase() || "";
      const searchedItem = item.name?.toLowerCase();

      if (searchedItem?.includes(text)) {
        return [...ids, item.id];
      } else {
        return ids;
      }
    },
    [] as (number | undefined)[]
  );
};

export const convertSummaryFromResponse = (response: SummaryResult) => {
  return {
    id: response[SUMMARY_RESPONSE.ID],
    userId: response[SUMMARY_RESPONSE.APP_USER],
    date: new Date(response[SUMMARY_RESPONSE.REPORTED_DATE]),
    projectId: response[SUMMARY_RESPONSE.PROJECT],
    summary: response[SUMMARY_RESPONSE.REPORTED_PROGRESS],
    plans: response[SUMMARY_RESPONSE.PLAN],
    issue: response[SUMMARY_RESPONSE.PROBLEM],
    edit: true,
  };
};

export const convertSummaryToPayload = (summary: Summary) => {
  return {
    [SUMMARY_RESPONSE.APP_USER]: summary.userId,
    [SUMMARY_RESPONSE.REPORTED_DATE]: summary.date.toISOString().split("T")[0],
    [SUMMARY_RESPONSE.REPORTED_PROGRESS]: summary.summary,
    [SUMMARY_RESPONSE.PLAN]: summary.plans,
    [SUMMARY_RESPONSE.PROBLEM]: summary.issue,
    [SUMMARY_RESPONSE.PROJECT]: summary.projectId,
  };
};
