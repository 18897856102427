import { RootState } from "../types";
import { FiltersState } from "./types";

const filtersList = (state: RootState): FiltersState | undefined =>
  state.filters;

const filtersSelectors = {
  filtersList,
};

export default filtersSelectors;
