import { DATA_ELEMENTS } from "../../components/table/types";
import { INPUT_TYPE, Text } from "../../utils/types";

export const text: Text = {
  [DATA_ELEMENTS.DATE]: "Data",
  [DATA_ELEMENTS.ISSUE]: "Problemy",
  [DATA_ELEMENTS.USER_ID]: "Imię i nazwisko",
  [DATA_ELEMENTS.PLANS]: "Plan na dziś",
  [DATA_ELEMENTS.PROJECT_ID]: "Projekt",
  [DATA_ELEMENTS.SUMMARY]: "Podsumowanie",
  allProjects: "Wszystkie projekty",
  allUsers: "Wszyscy pracownicy",
  checkboxIssues: "Tylko problemy",
  checkboxNoPlans: "Brak planu",
  noData: "There are no summaries.",
  titleEdit: "Edytuj podsumowanie",
  titleSummary: "Podsumowanie",
};

export const summaryPreviewElements: string[] = [
  DATA_ELEMENTS.USER_ID,
  DATA_ELEMENTS.SUMMARY,
  DATA_ELEMENTS.PLANS,
  DATA_ELEMENTS.ISSUE,
];

export const summaryEditableElements: string[] = [
  DATA_ELEMENTS.USER_ID,
  DATA_ELEMENTS.DATE,
  DATA_ELEMENTS.PROJECT_ID,
  DATA_ELEMENTS.SUMMARY,
  DATA_ELEMENTS.PLANS,
  DATA_ELEMENTS.ISSUE,
];

export const elementsFieldType: Record<string, string> = {
  [DATA_ELEMENTS.USER_ID]: INPUT_TYPE.SELECT,
  [DATA_ELEMENTS.DATE]: INPUT_TYPE.DATE,
  [DATA_ELEMENTS.PROJECT_ID]: INPUT_TYPE.SELECT,
  [DATA_ELEMENTS.SUMMARY]: INPUT_TYPE.MULTI_TEXT,
  [DATA_ELEMENTS.PLANS]: INPUT_TYPE.MULTI_TEXT,
  [DATA_ELEMENTS.ISSUE]: INPUT_TYPE.MULTI_TEXT,
};
