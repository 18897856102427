import { CheckboxFieldProps } from "./types";
import { Checkbox, FormControlLabel } from "@mui/material";
import useStyles from "./styles";

const CheckboxField = ({
  checked,
  label,
  name,
  onChange,
}: CheckboxFieldProps) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.root}
      control={
        <Checkbox
          checked={checked}
          disableRipple
          name={name}
          onChange={onChange}
          inputProps={{
            "aria-label": "checkbox",
          }}
        />
      }
      label={label}
    />
  );
};

export default CheckboxField;
