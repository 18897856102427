import { Auth, AuthError, RootUserState } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../users/types";

const initialState: RootUserState = {
  email: undefined,
  errorAuth: undefined,
  details: {
    email: undefined,
    name: undefined,
    is_staff: undefined,
    slack_id: undefined,
    id: undefined,
  },
  isAuth: undefined,
  loaded: false,
  name: undefined,
  token: undefined,
};

const rootUserSlice = createSlice({
  name: "rootUser",
  initialState,
  reducers: {
    logout: (state) => {
      state.email = undefined;
      state.errorAuth = undefined;
      state.isAuth = false;
      state.token = undefined;
    },
    setAuth: (state, action: PayloadAction<Auth>) => {
      state.email = action.payload.email;
      state.errorAuth = undefined;
      state.isAuth = Boolean(action.payload);
      state.token = action.payload.token;
    },
    setErrorAuth: (state, action: PayloadAction<AuthError>) => {
      state.email = undefined;
      state.errorAuth = action.payload;
      state.isAuth = false;
      state.token = undefined;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.details = action.payload;
    },
    setLoaded: (state) => {
      state.loaded = true;
    },
  },
});

export const rootUserSliceActions = rootUserSlice.actions;

export default rootUserSlice.reducer;
