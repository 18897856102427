import React from "react";
import {
  ALIGN_TYPE,
  DATA_TYPE,
  HeadCell,
  ICONS_TYPE,
  IconsByColumnType,
  IconsType,
  Order,
  ORDER_TYPE,
} from "../types";
import { Avatar } from "@mui/material";
import {
  RemoveOutlined,
  TaskAlt,
  ErrorOutlineOutlined,
} from "@mui/icons-material";
import COLOR from "../../../utils/color";

export const columnsOrder = [
  DATA_TYPE.NAME,
  DATA_TYPE.DATE,
  DATA_TYPE.PROJECTS,
  DATA_TYPE.SUMMARY,
  DATA_TYPE.PLANS,
  DATA_TYPE.ISSUE,
  DATA_TYPE.EDIT,
];

export const headCells: HeadCell[] = [
  {
    id: DATA_TYPE.NAME,
    align: ALIGN_TYPE.LEFT,
    label: "Imię i nazwisko",
    isSortable: true,
  },
  {
    id: DATA_TYPE.DATE,
    align: ALIGN_TYPE.LEFT,
    label: "Date",
    isSortable: true,
  },
  {
    id: DATA_TYPE.PROJECTS,
    align: ALIGN_TYPE.LEFT,
    label: "Projekt",
    isSortable: true,
  },
  {
    id: DATA_TYPE.SUMMARY,
    align: ALIGN_TYPE.CENTER,
    label: "Podsumowanie",
    isSortable: false,
  },
  {
    id: DATA_TYPE.PLANS,
    align: ALIGN_TYPE.CENTER,
    label: "Plan na dziś",
    isSortable: false,
  },
  {
    id: DATA_TYPE.ISSUE,
    align: ALIGN_TYPE.CENTER,
    label: "Problemy",
    isSortable: false,
  },
  { id: DATA_TYPE.EDIT, align: ALIGN_TYPE.RIGHT, label: "", isSortable: false },
];

export const icons: IconsType = {
  [ICONS_TYPE.AVATAR]: (
    <Avatar style={{ height: 30, marginRight: 10, width: 30 }} />
  ),
  [ICONS_TYPE.CHECK]: <TaskAlt style={{ color: COLOR.GREEN, fontSize: 24 }} />,
  [ICONS_TYPE.EDIT]: (
    <div
      style={{
        backgroundColor: COLOR.GREY_LIGHT,
        borderRadius: 8,
        color: COLOR.GREY_2,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "19px",
        padding: 10,
      }}
    >
      Edytuj
    </div>
  ),
  [ICONS_TYPE.NONE]: (
    <RemoveOutlined style={{ color: COLOR.GREY, fontSize: 24 }} />
  ),
  [ICONS_TYPE.WARNING]: (
    <ErrorOutlineOutlined style={{ color: COLOR.RED, fontSize: 24 }} />
  ),
};

export const iconsByColumnType: IconsByColumnType = {
  [DATA_TYPE.EDIT]: { positive: icons[ICONS_TYPE.EDIT] },
  [DATA_TYPE.ISSUE]: {
    positive: icons[ICONS_TYPE.WARNING],
    negative: icons[ICONS_TYPE.NONE],
  },
  [DATA_TYPE.NAME]: { avatar: icons[ICONS_TYPE.AVATAR] },
  [DATA_TYPE.PLANS]: {
    positive: icons[ICONS_TYPE.CHECK],
    negative: icons[ICONS_TYPE.WARNING],
  },
  [DATA_TYPE.SUMMARY]: {
    positive: icons[ICONS_TYPE.CHECK],
    negative: icons[ICONS_TYPE.NONE],
  },
};

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === ORDER_TYPE.DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
