import { RootState } from "../types";

const authError = (state: RootState): string | undefined =>
  state.rootUser.errorAuth;

const isAuth = (state: RootState): boolean | undefined => state.rootUser.isAuth;

const loaded = (state: RootState): boolean => state.rootUser.loaded;

const rootUserEmail = (state: RootState): string | undefined =>
  state.rootUser.email;

const token = (state: RootState): string | undefined => state.rootUser.token;

const rootUserSelectors = {
  authError,
  isAuth,
  loaded,
  rootUserEmail,
  token,
};

export default rootUserSelectors;
