import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { TextProps } from "./types";

const text: TextProps = {
  altImg: "OG Daily",
  srcImg: "static/images/logo.svg",
  title: "Tablica podsumowań",
};

const Logo: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={text.srcImg} alt={text.altImg} className={classes.logo} />
      <Typography className={classes.title}>{text.title}</Typography>
    </div>
  );
};

export default Logo;
