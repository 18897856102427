import { DialogTitle, IconButton } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import React from "react";
import useStyles from "./styles";
import { ModalHeaderProps } from "../types";
import { Text } from "../../../utils/types";

const text: Text = {
  buttonLabel: "close",
};

const ModalHeader = ({ handleCloseModal, title = "" }: ModalHeaderProps) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      {/* Title */}
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      {/* Close button */}
      <IconButton
        aria-label={text.buttonLabel}
        disableRipple
        className={classes.closeButton}
        onClick={handleCloseModal}
      >
        <Close className={classes.closeButtonIcon} />
      </IconButton>
    </div>
  );
};

export default ModalHeader;
