import React from "react";

export enum CELL_TYPE {
  HEADER = "header",
  BODY = "body",
}

export enum ALIGN_TYPE {
  CENTER = "center",
  LEFT = "left",
  RIGHT = "right",
}

export enum DATA_TYPE {
  SUMMARY_ID = "SUMMARY_ID",
  NAME = "NAME",
  DATE = "DATE",
  PROJECTS = "PROJECTS",
  SUMMARY = "SUMMARY",
  PLANS = "PLANS",
  ISSUE = "ISSUE",
  EDIT = "EDIT",
}

export enum DATA_ELEMENTS {
  DATE = "date",
  ISSUE = "issue",
  USER_ID = "userId",
  PLANS = "plans",
  PROJECT_ID = "projectId",
  SUMMARY = "summary",
}

export enum ORDER_TYPE {
  ASC = "asc",
  DESC = "desc",
}

export enum ICONS_TYPE {
  AVATAR = "AVATAR",
  CHECK = "CHECK",
  EDIT = "EDIT",
  NONE = "NONE",
  WARNING = "WARNING",
}

export type AlignType = ALIGN_TYPE.CENTER | ALIGN_TYPE.LEFT | ALIGN_TYPE.RIGHT;

export type CreateSortEvent = React.MouseEvent<unknown>;

export type CreateSortProperty = keyof typeof DATA_TYPE;

export type IconType = React.ReactNode;

export type CellWithButtonType =
  | DATA_TYPE.EDIT
  | DATA_TYPE.SUMMARY
  | DATA_TYPE.PLANS
  | DATA_TYPE.ISSUE;

export type IconsByColumnType = Record<
  | DATA_TYPE.EDIT
  | DATA_TYPE.ISSUE
  | DATA_TYPE.NAME
  | DATA_TYPE.PLANS
  | DATA_TYPE.SUMMARY,
  { positive?: IconType; negative?: IconType; avatar?: IconType }
>;

export type Order = ORDER_TYPE.ASC | ORDER_TYPE.DESC;

export type Ordering = {
  order: ORDER_TYPE.ASC | ORDER_TYPE.DESC;
  column: DATA_TYPE;
};

export interface Data {
  [DATA_TYPE.SUMMARY_ID]: number;
  [DATA_TYPE.DATE]: string;
  [DATA_TYPE.EDIT]: boolean;
  [DATA_TYPE.ISSUE]: string;
  [DATA_TYPE.NAME]: string;
  [DATA_TYPE.PLANS]: string;
  [DATA_TYPE.PROJECTS]: string;
  [DATA_TYPE.SUMMARY]: string;
}

export interface IconsType {
  [key: string]: IconType;
}

export interface EnhancedTableHeadProps {
  list: HeadCell[];
  onRequestSort?: (
    event: CreateSortEvent,
    property: CreateSortProperty
  ) => void;
  order: ORDER_TYPE;
  orderBy: DATA_TYPE;
}

export interface HeadCell {
  align: AlignType;
  avatar?: React.ReactNode;
  check?: boolean | string | number;
  disabled?: boolean;
  icon?: React.ReactNode;
  id: keyof typeof DATA_TYPE;
  isSortable?: boolean;
  label?: boolean | string | number;
  onClick?: () => void;
}

export interface TableCellProps {
  colSpan?: number;
  disabled?: boolean;
  icon?: React.ReactNode;
  item?: HeadCell;
  order: ORDER_TYPE;
  onClick?: (editable: boolean) => void;
  onRequestSort?: (
    event: CreateSortEvent,
    property: CreateSortProperty
  ) => void;
  orderBy: DATA_TYPE;
  type: CELL_TYPE;
}

export interface TableRowProps {
  colSpan?: number;
  list?: HeadCell[];
  onRequestSort?: (
    event: CreateSortEvent,
    property: CreateSortProperty
  ) => void;
  order: ORDER_TYPE;
  orderBy: DATA_TYPE;
  type: CELL_TYPE;
}

export interface TableBodyProps {
  list: HeadCell[][];
  order: ORDER_TYPE;
  orderBy: DATA_TYPE;
}

export type SelectNames = DATA_ELEMENTS.USER_ID | DATA_ELEMENTS.PROJECT_ID;
