export enum SUMMARY_RESPONSE {
  ID = "id",
  PLAN = "plan",
  PROBLEM = "problem",
  PROJECT = "project",
  /* eslint-disable camelcase */
  APP_USER = "app_user",
  CREATION_DATE = "creation_date",
  MESSAGE_SENT = "message_sent",
  REPORTED_DATE = "reported_date",
  REPORTED_PROGRESS = "reported_progress",
  /* eslint-enable camelcase */
}

export type SummariesListError = string;

export type SummariesListLoading = boolean;

export type SummaryId = number;

export interface Summary {
  id: number;
  userId: number;
  date: Date;
  projectId: number;
  summary: string;
  plans: string;
  issue: string;
  edit: boolean;
}

export interface SummaryResponse {
  count: number;
  next: string;
  previous: string;
  results: SummaryResult[];
}

export interface SummaryResult {
  [SUMMARY_RESPONSE.ID]: number;
  [SUMMARY_RESPONSE.PLAN]: string;
  [SUMMARY_RESPONSE.PROBLEM]: string;
  [SUMMARY_RESPONSE.PROJECT]: number;
  /* eslint-disable camelcase */
  [SUMMARY_RESPONSE.APP_USER]: number;
  [SUMMARY_RESPONSE.CREATION_DATE]: string;
  [SUMMARY_RESPONSE.MESSAGE_SENT]: boolean;
  [SUMMARY_RESPONSE.REPORTED_DATE]: string;
  [SUMMARY_RESPONSE.REPORTED_PROGRESS]: string;
  /* eslint-enable camelcase */
}

export interface SummaryPayload {
  count: number;
  summaries?: Summary[];
}

export interface SummaryUpdate {
  plan: string;
  problem: string;
  project: number;
  /* eslint-disable camelcase */
  app_user: number;
  reported_date: string;
  reported_progress: string;
  /* eslint-enable camelcase */
}

export interface SummariesState {
  list?: Summary[];
  count: number;
  isLoading: boolean;
  error?: string;
}
