import React from "react";
import { TableRow as MaterialTableRow } from "@mui/material";
import TableCell from "../TableCell";
import { CELL_TYPE, HeadCell, TableRowProps } from "../types";
import useStyles from "./styles";
import classNames from "classnames";

const TableRow = ({
  colSpan,
  onRequestSort,
  list,
  order,
  orderBy,
  type,
}: TableRowProps) => {
  const classes = useStyles();
  return (
    <MaterialTableRow
      className={classNames({
        [classes.root]: type === CELL_TYPE.BODY && !colSpan,
      })}
    >
      {list?.map((item: HeadCell | any, index: number) => (
        <TableCell
          key={index}
          onRequestSort={onRequestSort}
          type={type}
          item={item}
          order={order}
          orderBy={orderBy}
        />
      ))}
      {/* Empty cell */}
      {colSpan && (
        <TableCell
          type={type}
          order={order}
          orderBy={orderBy}
          colSpan={colSpan}
        />
      )}
    </MaterialTableRow>
  );
};

export default TableRow;
