import { RootState } from "../types";
import { SummariesListError, Summary } from "./types";

const summariesCount = (state: RootState): number => state.summaries.count;

const summariesList = (state: RootState): Summary[] | undefined =>
  state.summaries.list;

const summariesListError = (state: RootState): SummariesListError | undefined =>
  state.summaries.error;

const summariesListLoading = (state: RootState): boolean =>
  state.summaries.isLoading;

const summariesSelectors = {
  summariesCount,
  summariesList,
  summariesListError,
  summariesListLoading,
};

export default summariesSelectors;
