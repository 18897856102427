import { INPUT_VARIANT } from "../../../utils/types";
import { MultilineFieldProps } from "./types";
import useStyles from "./styles";
import { TextField } from "@mui/material";
import { replaceSpaceToDash } from "../../../utils/functions";

const MultilineField = ({ id, name, onChange, value }: MultilineFieldProps) => {
  const classes = useStyles();
  const fieldId: string = replaceSpaceToDash(id);

  return (
    <TextField
      className={classes.multilineField}
      defaultValue={value}
      id={fieldId}
      multiline
      name={name}
      onChange={onChange}
      variant={INPUT_VARIANT.OUTLINED}
    />
  );
};

export default MultilineField;
