import { EditModal, ModalState, OpenModal, SelectedSummaryId } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ModalState = {
  isEdit: false,
  isOpen: false,
  selectedSummaryId: undefined,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    toggleOpenModal: (state, action: PayloadAction<OpenModal>) => {
      state.isOpen = action.payload;
      state.isEdit = !action.payload ? false : state.isEdit;
      state.selectedSummaryId = !action.payload
        ? undefined
        : state.selectedSummaryId;
    },
    toggleEditModal: (state, action: PayloadAction<EditModal>) => {
      state.isOpen = true;
      state.isEdit = action.payload;
    },
    setSelectedSummaryId: (state, action: PayloadAction<SelectedSummaryId>) => {
      state.selectedSummaryId = action.payload;
    },
  },
});

export const modalSliceActions = modalSlice.actions;

export default modalSlice.reducer;
