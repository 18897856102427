import React, { useCallback, useEffect, useState } from "react";
import {
  SortDirection,
  TableCell as MaterialTableCell,
  TableSortLabel,
} from "@mui/material";
import classNames from "classnames";
import useStyles from "./styles";
import {
  CELL_TYPE,
  CreateSortEvent,
  CreateSortProperty,
  DATA_TYPE,
  ORDER_TYPE,
  TableCellProps,
} from "../types";
import ButtonIcon from "../../buttons/ButtonIcon";

const TableCell = ({
  colSpan,
  item,
  order,
  orderBy,
  onRequestSort,
  type,
}: TableCellProps) => {
  const classes = useStyles();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isHeader, setIsHeader] = useState<boolean>(false);
  const [direction, setDirection] = useState<ORDER_TYPE>(ORDER_TYPE.ASC);
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    false
  );

  const createSortHandler = useCallback(
    (property: CreateSortProperty) => (event: CreateSortEvent) => {
      onRequestSort && onRequestSort(event, property);
    },
    [onRequestSort]
  );

  // Set sort direction
  useEffect(() => {
    setSortDirection(orderBy === item?.id ? order : false);
  }, [item?.id, order, orderBy]);

  // Set direction
  useEffect(() => {
    setDirection(orderBy === item?.id ? order : ORDER_TYPE.ASC);
  }, [item?.id, order, orderBy]);

  // Set is header cell
  useEffect(() => {
    setIsHeader(type === CELL_TYPE.HEADER);
  }, [type]);

  // Set is active cell
  useEffect(() => {
    item?.id && setIsActive(orderBy === item?.id);
  }, [item?.id, orderBy, type]);

  return (
    <>
      {isHeader ? (
        <MaterialTableCell
          sortDirection={sortDirection}
          align={item?.align}
          className={classNames(classes.root, {
            [classes.cellHeader]: isHeader,
            [classes.hiddenArrow]: !item?.label,
          })}
        >
          {item?.isSortable ? (
            <TableSortLabel
              active={isActive}
              direction={direction}
              onClick={
                item?.id &&
                createSortHandler(item?.id as keyof typeof DATA_TYPE)
              }
            >
              {item?.label}
            </TableSortLabel>
          ) : (
            <> {item?.label}</>
          )}
        </MaterialTableCell>
      ) : (
        <MaterialTableCell
          align={item?.align}
          className={classNames(classes.root, {
            [classes.cellBody]: item,
            [classes.emptyCell]: !item,
          })}
          colSpan={colSpan}
        >
          {/* Label */}
          {item?.avatar && (
            <div className={classes.editButton}>
              {item?.avatar}
              {!colSpan && item?.label}
            </div>
          )}
          {!item?.avatar && !colSpan && item?.label}
          {item?.icon && item?.onClick && (
            <ButtonIcon
              icon={item.icon}
              onClick={item.onClick}
              disabled={item.disabled}
            />
          )}
        </MaterialTableCell>
      )}
    </>
  );
};

export default TableCell;
