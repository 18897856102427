import { Alert } from "@mui/material";
import React from "react";
import { AlertMessageProps } from "./types";
import { SEVERITY } from "../../utils/types";
import useStyles from "./styles";

const AlertMessage = ({
  severity = SEVERITY.INFO,
  text,
}: AlertMessageProps) => {
  const classes = useStyles();

  return (
    <Alert severity={severity} className={classes.message}>
      {text || "-"}
    </Alert>
  );
};

export default AlertMessage;
