import { rootUserSliceActions } from "./reducer";
import { Auth, AuthError, Login } from "./types";
import { PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState, Token } from "../types";
import { rootUserServices } from "./services";
import { getCookie, removeCookie, setCookie } from "../../app/utils/cookie";
import { COOKIE } from "../../app/utils/types";

const login = ({
  email,
  password,
}: Login): ThunkAction<
  void,
  RootState,
  unknown,
  PayloadAction<Auth | AuthError>
> => {
  return (dispatch) => {
    rootUserServices
      .login({ email, password })
      .then(({ data: { token } }: { data: { token: Token } }) => {
        if (token) {
          dispatch(rootUserSliceActions.setAuth({ email, token }));
          setCookie(COOKIE.TOKEN, token);
        } else {
          dispatch(
            rootUserSliceActions.setErrorAuth(
              "There is no TOKEN. Please, contact with administrator."
            )
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          const response = err.response.data;
          const error =
            (response?.non_field_errors && response?.non_field_errors[0]) ||
            `${err.message}. Please, contact with administrator.`;

          console.error(response);
          dispatch(rootUserSliceActions.setErrorAuth(error));
        } else if (err.request) {
          console.error(err.request);
        } else {
          console.error(`Error: ${err.message}`);
        }
        console.error(err.config);
      });
  };
};

const logout = (): ThunkAction<void, RootState, unknown, PayloadAction> => {
  return (dispatch) => {
    dispatch(rootUserSliceActions.logout());
    removeCookie(COOKIE.TOKEN);
  };
};

const me = (): ThunkAction<
  void,
  RootState,
  unknown,
  PayloadAction<Auth | AuthError | undefined>
> => {
  return (dispatch) => {
    rootUserServices
      .me()
      .then(({ data: { email } }: { data: { email: string } }) => {
        if (email) {
          const token = getCookie(COOKIE.TOKEN);
          token && dispatch(rootUserSliceActions.setAuth({ email, token }));
          token && dispatch(rootUserSliceActions.setLoaded());
        } else {
          dispatch(
            rootUserSliceActions.setErrorAuth(
              "There is no TOKEN. Please, contact with administrator."
            )
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          const response = err.response.data;
          const error =
            (response?.non_field_errors && response?.non_field_errors[0]) ||
            `${err.message}. Please, contact with administrator.`;

          console.error(response);
          dispatch(rootUserSliceActions.setErrorAuth(error));
        } else if (err.request) {
          console.error(err.request);
        } else {
          console.error(`Error: ${err.message}`);
        }
        console.error(err.config);
      });
  };
};

const rootUserActions = {
  ...rootUserSliceActions,
  login,
  logout,
  me,
};

export default rootUserActions;
