import { Button } from "@material-ui/core";
import { ButtonTextProps } from "./types";
import { useStyles } from "./styles";
import classNames from "classnames";
import { OG_TYPE } from "../../../utils/types";

const ButtonText = ({
  buttonType,
  fullWidth = false,
  onClick,
  text,
  variant,
}: ButtonTextProps) => {
  const classes = useStyles();

  return (
    <Button
      className={classNames(classes.button, {
        [classes.buttonBrand]: buttonType === OG_TYPE.BRAND,
        [classes.buttonTransparent]: buttonType === OG_TYPE.TRANSPARENT,
      })}
      fullWidth={fullWidth}
      disableRipple
      onClick={onClick}
      variant={variant}
    >
      {text}
    </Button>
  );
};

export default ButtonText;
