import { RootState } from "../types";
import { Project } from "./types";

const projectsList = (state: RootState): Project[] | undefined =>
  state.projects.list;

const projectsListLoading = (state: RootState): boolean =>
  state.projects.isLoading;

const projectsSelectors = {
  projectsList,
  projectsListLoading,
};

export default projectsSelectors;
