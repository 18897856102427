import { createStyles, makeStyles } from "@material-ui/core/styles";
import COLOR from "../../../utils/color";
import { FONT } from "../../../utils/font";

const useStyles = makeStyles(() =>
  createStyles({
    closeButton: {
      border: `1px solid ${COLOR.GREY_LIGHT_2}`,
      borderRadius: 8,
      padding: 5,
    },

    closeButtonIcon: {
      color: COLOR.GREY,
      fontSize: 28,
    },

    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 10,
    },

    title: {
      margin: "10px 0 0 10px",
      padding: 0,

      "& .MuiTypography-root": {
        ...FONT.LARGE,
      },
    },
  })
);

export default useStyles;
