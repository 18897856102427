import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: "12px 0 2px",

      "& > *": {
        marginBottom: "10px !important",
        marginRight: "10px !important",
      },
    },
  })
);

export default useStyles;
