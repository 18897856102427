import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import FormField from "../FormField";
import Button from "../buttons/Button";
import {
  BUTTON_TYPE,
  INPUT_TYPE,
  INPUT_VARIANT,
  OG_TYPE,
} from "../../utils/types";
import { TextProps } from "./types";
import { validationSchema } from "../../utils/schema";
import rootUserActions from "../../../redux/rootUser/action";
import rootUserSelectors from "../../../redux/rootUser/selectors";

const text: TextProps = {
  email: "email",
  labelEmail: "Email",
  labelPassword: "Hasło",
  loginButton: "Zaloguj",
  password: "password",
  subtitle: "Używając swojego konta firmowego",
  title: "Zaloguj się",
};

const LoginForm: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const authError = useSelector(rootUserSelectors.authError);

  useEffect(() => {
    const message = authError || "";

    setErrorMessage(message);
  }, [authError]);

  const handleLogin = useCallback(
    (email: string, password: string) => {
      dispatch(rootUserActions.login({ email, password }));
    },
    [dispatch]
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { email, password } = values;

      handleLogin(email, password);
    },
  });

  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        {text.title}
      </Typography>
      <Typography className={classes.subtitle}>{text.subtitle}</Typography>

      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <div className={classes.formFields}>
          {/* Email */}
          <FormField
            error={formik.touched.email && Boolean(formik.errors.email)}
            fullWidth={true}
            helperText={formik.touched.email && formik.errors.email}
            id={text.email}
            label={text.labelEmail}
            name={text.email}
            onChange={formik.handleChange}
            value={formik.values.email}
            variant={INPUT_VARIANT.STANDARD}
          />
          {/* Password */}
          <FormField
            error={formik.touched.password && Boolean(formik.errors.password)}
            fullWidth={true}
            helperText={formik.touched.password && formik.errors.password}
            id={text.password}
            label={text.labelPassword}
            name={text.password}
            onChange={formik.handleChange}
            type={INPUT_TYPE.PASSWORD}
            value={formik.values.password}
            variant={INPUT_VARIANT.STANDARD}
          />
          {/* Error message */}
          {errorMessage && (
            <Typography className={classes.errorMessage}>
              {errorMessage}
            </Typography>
          )}
        </div>
        {/* Submit button */}
        <Button
          fullWidth={true}
          text={text.loginButton}
          type={BUTTON_TYPE.SUBMIT}
          ogType={OG_TYPE.BRAND}
        />
      </form>
    </div>
  );
};

export default LoginForm;
