import { createStyles, makeStyles } from "@material-ui/core/styles";
import COLOR from "../../../utils/color";
import { FONT } from "../../../utils/font";

const useStyles = makeStyles(() =>
  createStyles({
    multilineField: {
      backgroundColor: COLOR.GREY_LIGHT,
      borderRadius: 8,
      overflow: "hidden",
      width: "100%",

      "& .MuiInputBase-root": {
        ...FONT.PARAGRAPH,
        borderRadius: 8,
        color: COLOR.GREY,
        fontWeight: 600,
        lineHeight: "19px",
        padding: "10px 15px",
      },

      "& fieldset": {
        borderColor: `${COLOR.GREY_LIGHT} !important`,
      },

      "& .MuiInputBase-inputMultiline": {
        minHeight: 100,
      },
    },
  })
);

export default useStyles;
