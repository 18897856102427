import { createStyles, makeStyles } from "@material-ui/core/styles";
import COLOR from "../../utils/color";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "absolute",
      top: "50%",
      left: "50%",
      WebkitTransform: "translate(-50%, -50%)",
      color: COLOR.BLACK,
    },
  })
);

export default useStyles;
