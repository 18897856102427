import { createStyles, makeStyles } from "@material-ui/core/styles";
import COLOR from "../../utils/color";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: COLOR.GREY_LIGHT_3,
      flex: 1,
      overflow: "auto",
    },
    // Dashboard
    dashboard: {
      flex: 1,
      margin: 30,
      marginBottom: 60,
      width: "calc(100% - 60px)",
    },
    pagination: {
      position: "fixed",
      left: 0,
      bottom: 0,
      width: "100%",
    },
  })
);

export default useStyles;
