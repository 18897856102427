import { useStyles } from "./styles";
import React from "react";
import { ButtonIconProps } from "./types";
import { IconButton } from "@mui/material";
import { ICON_SIZE } from "../../../utils/types";
import classNames from "classnames";

const ButtonIcon = ({ disabled = false, icon, onClick }: ButtonIconProps) => {
  const classes = useStyles();

  return (
    <IconButton
      className={classNames(classes.root, { [classes.disabled]: disabled })}
      disableRipple
      disabled={disabled}
      onClick={onClick}
      size={ICON_SIZE.SMALL}
    >
      {icon}
    </IconButton>
  );
};

export default ButtonIcon;
