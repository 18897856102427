import { Login } from "./types";
import { ROOT_USER_URLS } from "./constants";
import axios, { AxiosPromise } from "axios";
import axiosInstance from "../axiosInstance";

const login = (data: Login): AxiosPromise => {
  const url: string = ROOT_USER_URLS.loginUrl;

  return axios({
    url,
    method: "POST",
    data,
  });
};

const me = (): AxiosPromise => {
  const url: string = ROOT_USER_URLS.meUrl;

  return axiosInstance({
    url,
    method: "GET",
  });
};

// TODO: Commented temporary - waiting for backend
// const logout = (): AxiosPromise => {
//   const url: string = "";
//
//   return axios({
//     url,
//     method: "POST",
//   });
// };

export const rootUserServices = {
  login,
  me,
  // logout,
};
