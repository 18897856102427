import { createStyles, makeStyles } from "@material-ui/core/styles";
import { FONT } from "../../utils/font";

const useStyles = makeStyles(() =>
  createStyles({
    message: {
      "& .MuiAlert-message": {
        ...FONT.SMALL,
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
      },
    },
  })
);

export default useStyles;
