import { createStyles, makeStyles } from "@material-ui/core/styles";
import { FONT } from "../../utils/font";
import COLOR from "../../utils/color";

export const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      alignItems: "center",
      display: "flex",
    },
    logo: {
      display: "block",
      height: 20,
      objectFit: "contain",
      width: 26,
    },
    title: {
      ...FONT.SMALL,
      color: COLOR.WHITE,
      fontWeight: 800,
      lineHeight: 1,
      marginLeft: 8,
      textTransform: "uppercase",
      width: 100,
    },
  });
});
