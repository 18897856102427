import { createStyles, makeStyles } from "@material-ui/core/styles";
import COLOR from "../../../utils/color";
import { FONT } from "../../../utils/font";

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: 195,

      "& fieldset": {
        borderColor: `${COLOR.GREY_LIGHT_2} !important`,
      },

      "& .MuiInputBase-root": {
        borderRadius: 8,
        overflow: "hidden",
      },

      "& .MuiSelect-selectMenu": {
        ...FONT.PARAGRAPH,
        backgroundColor: COLOR.TRANSPARENT,
        color: COLOR.GREY,
        fontWeight: 600,
        padding: "11px 35px 11px 15px",
      },

      "& .MuiMenuItem-root": {
        ...FONT.PARAGRAPH,
        color: COLOR.GREY,
        fontWeight: 600,
      },

      "& .MuiSelect-icon": {
        color: COLOR.GREY,
        fontSize: "1.8rem",
        marginTop: 2,
        right: 12,
        transform: "rotate(90deg)",
      },
    },
    fullWidth: {
      width: "100%",
    },
  })
);

export default useStyles;
