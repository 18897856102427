import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import App from "./app";
import { configureStore } from "./redux/configureStore";
import { Provider } from "react-redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { plPL } from "@mui/material/locale";
import DateFnsUtils from "@date-io/date-fns";

const store = configureStore();
const rootEl = document.getElementById("root");

const theme = createTheme(plPL);

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>,
    rootEl
  );
};

window.onload = () => render();
