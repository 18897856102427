import { RootState } from "../types";
import { User } from "./types";

const usersList = (state: RootState): User[] | undefined => state.users.list;

const usersLoading = (state: RootState): boolean => state.users.isLoading;

const usersSelectors = {
  usersList,
  usersLoading,
};

export default usersSelectors;
