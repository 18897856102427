import { createStyles, makeStyles } from "@material-ui/core/styles";
import COLOR from "../../../utils/color";

const modalMargin = 10;

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      "& .MuiDialog-container": {
        alignItems: "end",
        justifyContent: "flex-end",
      },

      "& .MuiPaper-root": {
        backgroundColor: COLOR.WHITE,
        borderRadius: 8,
        boxShadow: "none",
        height: `calc(100% - ${2 * modalMargin}px)`,
        margin: modalMargin,
        maxHeight: "unset",
        width: 400,
        padding: 2 * modalMargin,
      },

      "& .MuiBackdrop-root": {
        backgroundColor: COLOR.BLACK_TRANSPARENCY,
      },
    },

    modalContent: {
      "&.MuiDialogContent-root": {
        display: "flex",
        flexDirection: "column",
        margin: 0,
        padding: 0,
      },
    },
  })
);

export default useStyles;
