import { createStyles, makeStyles } from "@material-ui/core/styles";
import COLOR from "../../../utils/color";
import { FONT } from "../../../utils/font";

const useStyles = makeStyles(() =>
  createStyles({
    cellBody: {
      "&.MuiTableCell-root": {
        background: COLOR.WHITE,
        fontSize: 14,
        padding: "5px 10px",

        "&:first-child": {
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
        },
        "&:last-child": {
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
          paddingRight: 5,
        },
      },
    },
    cellHeader: {
      background: COLOR.TRANSPARENT,
    },
    editButton: {
      alignItems: "center",
      display: "flex",
    },
    emptyCell: {
      "&.MuiTableCell-root": {
        lineHeight: 0,
        padding: 4,
      },
    },
    hiddenArrow: {
      "& .MuiSvgIcon-root": {
        display: "none",
      },
    },
    root: {
      "&.MuiTableCell-root": {
        border: "none",
        lineHeight: "30px",
      },

      "&.MuiTableCell-head": {
        ...FONT.SMALL,
        color: COLOR.GREY_LIGHT_4,
        lineHeight: "16px",
        padding: 18,
      },
    },
  })
);

export default useStyles;
