import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      "&.MuiTableContainer-root": {
        overflow: "unset",
      },
    },
  })
);

export default useStyles;
