import { createStyles, makeStyles } from "@material-ui/core/styles";
import COLOR from "../../../utils/color";
import { FONT } from "../../../utils/font";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      "&.MuiFormControlLabel-root": {
        display: "inline-flex",
        marginLeft: 0,
      },

      "& .MuiTypography-root": {
        ...FONT.PARAGRAPH,
        fontWeight: 600,
        color: COLOR.GREY,
      },

      "& .MuiSvgIcon-root": {
        fill: COLOR.BLACK,
        height: 24,
        width: 24,
      },
    },
  })
);

export default useStyles;
