import { createStyles, makeStyles } from "@material-ui/core/styles";
import { FONT } from "../../utils/font";
import COLOR from "../../utils/color";

export const useStyles = makeStyles(() => {
  return createStyles({
    button: {
      color: COLOR.BLACK,
      fontSize: 16,
      letterSpacing: "0.1em",
      lineHeight: "19px",
      padding: "16px 40px",
      marginTop: 20,
      borderRadius: 0,
      background: COLOR.YELLOW,
      boxShadow: "none",

      "&:hover": {
        boxShadow: "none",
      },
    },
    errorMessage: {
      bottom: 25,
      color: COLOR.RED,
      fontSize: 16,
      fontWeight: "bold",
      lineHeight: "16px",
      margin: "10px auto",
      position: "absolute",
      textAlign: "center",
    },
    form: {
      paddingTop: 10,
    },
    formFields: {
      paddingBottom: 80,
      position: "relative",
    },
    root: {
      background: COLOR.WHITE,
      padding: "34px 60px",
      maxWidth: 500,
      width: "100%",
    },
    subtitle: {
      ...FONT.SUBTITLE,
      marginTop: 22,
      textAlign: "center",
    },
    title: {
      ...FONT.TITLE,
      marginBottom: 10,
      textAlign: "center",
    },
  });
});
