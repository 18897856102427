import React from "react";
import { DialogActions, DialogContent, Typography } from "@material-ui/core";
import useStyles from "./style";
import { ModalSummaryProps } from "../types";
import { Text } from "../../../utils/types";
import ButtonText from "../../buttons/ButtonText";

const text: Text = {
  editButton: "Edytuj wpis",
};

const ModalSummary = ({ data, onEdit }: ModalSummaryProps) => {
  const classes = useStyles();

  return (
    <>
      <DialogContent className={classes.rowsContainer}>
        {data &&
          [...data].map((item, index) => {
            return (
              <div key={index} className={classes.row}>
                <Typography className={classes.subtitle}>
                  {item?.subtitle || "-"}
                </Typography>
                <Typography className={classes.text}>
                  {item?.text || "-"}
                </Typography>
              </div>
            );
          })}
      </DialogContent>
      <DialogActions className={classes.buttonContainer}>
        {onEdit && <ButtonText onClick={onEdit} text={text.editButton} />}
      </DialogActions>
    </>
  );
};

export default ModalSummary;
