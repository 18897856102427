import { createStyles, makeStyles } from "@material-ui/core/styles";
import { FONT } from "../../utils/font";
import COLOR from "../../utils/color";

export const useStyles = makeStyles(() => {
  return createStyles({
    button: {
      background: COLOR.GREY_LIGHT_5,
      borderRadius: 8,
      padding: 8,

      "&:hover": {
        background: COLOR.GREY_LIGHT_6,
      },
    },
    iconLogout: {
      color: COLOR.WHITE,
    },
    logo: {
      flex: 1,
    },
    navbar: {
      background: COLOR.TRANSPARENT,
      boxShadow: "none",
    },
    navbarLogged: {
      background: COLOR.BLACK,
    },
    root: {},
    text: {
      ...FONT.PARAGRAPH,
      color: COLOR.WHITE,
      marginRight: 20,
    },
  });
});
