import { Filter, FILTERS, FiltersState } from "./types";
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

const initialState: FiltersState = {
  [FILTERS.DATE_FROM]: undefined,
  [FILTERS.DATE_TO]: undefined,
  [FILTERS.USER_ID]: undefined,
  [FILTERS.PROJECT_ID]: undefined,
  [FILTERS.ISSUE]: false,
  [FILTERS.PLANS]: false,
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setFilter: (state: Draft<FiltersState>, action: PayloadAction<Filter>) => {
      state[action.payload.name] = action.payload.value;
    },
  },
});

export const filtersSliceActions = filtersSlice.actions;

export default filtersSlice.reducer;
