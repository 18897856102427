import { usersSliceActions } from "./reducer";
import { User, UsersListError, UsersListLoading } from "./types";
import { RootState } from "../types";
import { PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { usersServices } from "./services";

const getUsersList = (): ThunkAction<
  void,
  RootState,
  unknown,
  PayloadAction<User[] | UsersListError | UsersListLoading>
> => {
  return (dispatch) => {
    dispatch(usersSliceActions.setUsersListLoading(true));
    usersServices
      .getUsersList()
      .then((data) => data?.data)
      .then((response: User[]) => {
        dispatch(usersSliceActions.setUsersList(response));
      })
      .catch((err) => {
        if (err.response) {
          console.error(err.response);
        } else if (err.request) {
          console.error(err.request);
        } else {
          console.error(`Error: ${err.message}`);
        }
        console.error(err.config);
        dispatch(usersSliceActions.setUsersListError(err.message));
      });
  };
};

const usersActions = {
  ...usersSliceActions,
  getUsersList,
};

export default usersActions;
