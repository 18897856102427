import { createStyles, makeStyles } from "@material-ui/core/styles";
import COLOR from "../../../utils/color";
import { FONT } from "../../../utils/font";

const useStyles = makeStyles(() =>
  createStyles({
    dateField: {
      width: 138,

      "& fieldset": {
        borderColor: `${COLOR.TRANSPARENT} !important`,
      },

      "& .MuiInputBase-root": {
        background: COLOR.GREY_LIGHT,
        borderRadius: 8,
        overflow: "hidden",
        padding: 0,
      },

      "& .MuiInputBase-input": {
        ...FONT.PARAGRAPH,
        backgroundColor: COLOR.TRANSPARENT,
        color: COLOR.GREY,
        fontWeight: 600,
        height: "unset",
        padding: "11px 5px 11px 15px",
      },

      "& .MuiButtonBase-root:hover": {
        backgroundColor: COLOR.TRANSPARENT,
      },

      "& .MuiInputAdornment-positionEnd": {
        marginLeft: 0,
      },

      "& .MuiSvgIcon-root": {
        color: COLOR.GREY,
        fontSize: "1.8rem",
        marginTop: 2,
        right: 12,
      },

      "& .MuiFormHelperText-root.Mui-error": {
        ...FONT.SMALL,
        backgroundColor: COLOR.TRANSPARENT,
        color: COLOR.RED,
        fontWeight: 600,
      },
    },
    fullWidth: {
      width: "100%",
    },
  })
);

export default useStyles;
