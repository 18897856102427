import { DialogActions, DialogContent } from "@material-ui/core";
import React from "react";
import useStyles from "./style";
import ButtonText from "../../buttons/ButtonText";
import { ModalEditProps } from "../types";
import { VARIANT, OG_TYPE, Text, INPUT_TYPE } from "../../../utils/types";
import classNames from "classnames";
import SelectField from "../../fields/SelectField";
import { Typography } from "@mui/material";
import MultilineField from "../../fields/MultilineField";
import DateField from "../../fields/DateField";

const text: Text = {
  buttonCancel: "Anuluj",
  buttonSave: "Zapisz",
  buttonRemove: "Usuń podsumowanie",
};

const ModalEditableSummary = ({
  data,
  onCloseEdit,
  onSaveChanges,
  onRemoveSummary,
}: ModalEditProps) => {
  const classes = useStyles();

  return (
    <>
      {/* Content */}
      <DialogContent className={classes.rowsContainer}>
        {data &&
          data.map((item, index) => {
            return (
              <div className={classes.row} key={index}>
                <Typography className={classes.subtitle}>
                  {item?.subtitle || "-"}
                </Typography>
                {item.type === INPUT_TYPE.SELECT && item?.onChange && (
                  <SelectField
                    fullWidth
                    id={item?.subtitle}
                    name={item?.name}
                    list={item?.list}
                    onChange={item?.onChange}
                    value={item.value as number}
                  />
                )}
                {item.type === INPUT_TYPE.DATE &&
                  item?.onChangeDate &&
                  typeof item?.value !== "boolean" && (
                    <DateField
                      fullWidth
                      id={item?.subtitle}
                      name={item?.name}
                      onChange={item?.onChangeDate}
                      value={item.value as Date}
                    />
                  )}
                {item.type === INPUT_TYPE.MULTI_TEXT && item?.onChange && (
                  <MultilineField
                    id={item?.subtitle}
                    name={item?.name}
                    onChange={item?.onChange}
                    value={item.value as string}
                  />
                )}
              </div>
            );
          })}
      </DialogContent>
      {/* Buttons */}
      <DialogActions className={classes.buttonsContainer}>
        <div className={classes.buttonsWrapper}>
          {onCloseEdit && (
            <ButtonText
              onClick={onCloseEdit}
              text={text.buttonCancel}
              variant={VARIANT.CONTAINED}
              buttonType={OG_TYPE.TRANSPARENT}
              fullWidth
            />
          )}
          {onSaveChanges && (
            <ButtonText
              onClick={onSaveChanges}
              text={text.buttonSave}
              variant={VARIANT.CONTAINED}
              buttonType={OG_TYPE.BRAND}
              fullWidth
            />
          )}
        </div>
        <div
          className={classNames(
            classes.buttonsWrapper,
            classes.oneButtonWrapper
          )}
        >
          {onRemoveSummary && (
            <ButtonText
              onClick={onRemoveSummary}
              text={text.buttonRemove}
              variant={VARIANT.CONTAINED}
            />
          )}
        </div>
      </DialogActions>
    </>
  );
};

export default ModalEditableSummary;
