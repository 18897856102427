import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import useStyles from "./style";
import Modal from "../../components/modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import rootUserSelectors from "../../../redux/rootUser/selectors";
import EnhancedTable from "../../components/table/EnhancedTable";
import { rootUserSliceActions } from "../../../redux/rootUser/reducer";
import projectsActions from "../../../redux/projects/action";
import usersActions from "../../../redux/users/action";
import summariesActions from "../../../redux/summaries/action";
import usersSelectors from "../../../redux/users/selectors";
import { modalSliceActions } from "../../../redux/modal/reducer";
import modalSelectors from "../../../redux/modal/selectors";
import {
  DATA_ELEMENTS,
  DATA_TYPE,
  Ordering,
  ORDER_TYPE,
  SelectNames,
} from "../../components/table/types";
import summariesSelectors from "../../../redux/summaries/selectors";
import { Summary } from "../../../redux/summaries/types";
import { ModalSummaryItem } from "../../../redux/modal/types";
import { INPUT_TYPE, SelectItemsList, SEVERITY } from "../../utils/types";
import AlertMessage from "../../components/AlertMessage";
import {
  elementsFieldType,
  summaryEditableElements,
  summaryPreviewElements,
  text,
} from "./config";
import ModalEditableSummary from "../../components/modal/ModalEditableSummary";
import ModalSummary from "../../components/modal/ModalSummary";
import projectsSelectors from "../../../redux/projects/selectors";
import {
  convertDataForSelect,
  convertSummaryToPayload,
  // getItemsIdSearchedByName,
} from "../../utils/functions";
import { Paper } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import FiltersBar from "../../components/FiltersBar";
import { FILTERS } from "../../../redux/filters/types";
import filtersSelectors from "../../../redux/filters/selectors";
import { filtersSliceActions } from "../../../redux/filters/reducer";
import { FilterData } from "../../components/FiltersBar/types";
import PaginationBar from "../../components/Pagination";
import Loader from "../../components/Loader";

const Dashboard: React.FC = () => {
  const [convertedProjectsList, setConvertedProjectsList] = useState<
    SelectItemsList | undefined
  >();
  const [convertedUsersList, setConvertedUsersList] = useState<
    SelectItemsList | undefined
  >();
  const [
    editableSelectedSummaryData,
    setEditableSelectedSummaryData,
  ] = useState<Summary | undefined>();
  const [filters, setFilters] = useState<FilterData[] | undefined>();
  const [
    filteredSelectedSummaryData,
    setFilteredSelectedSummaryData,
  ] = useState<ModalSummaryItem[] | undefined>();
  const [selectedSummaryData, setSelectedSummaryData] = useState<
    Summary | undefined
  >();
  const [initFilter, setInitFilter] = useState<boolean>(true);
  const [summaryEditableData, setSummaryEditableData] = useState<
    ModalSummaryItem[] | undefined
  >();
  const [summaryPreviewData, setSummaryPreviewData] = useState<
    ModalSummaryItem[] | undefined
  >();
  const [modalTitle, setModalTitle] = useState<string | undefined>();
  const [ordering, setOrdering] = useState<Ordering>({
    column: DATA_TYPE.DATE,
    order: ORDER_TYPE.DESC,
  });
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const filtersList = useSelector(filtersSelectors.filtersList);
  const isEditModal = useSelector(modalSelectors.isEditModal);
  const isOpenModal = useSelector(modalSelectors.isOpenModal);
  const projectsList = useSelector(projectsSelectors.projectsList);
  const rootUserEmail = useSelector(rootUserSelectors.rootUserEmail);
  const selectedSummaryId = useSelector(modalSelectors.selectedSummaryId);
  const summariesList = useSelector(summariesSelectors.summariesList);
  const summariesCount = useSelector(summariesSelectors.summariesCount);
  const isLoadingSummaries = useSelector(
    summariesSelectors.summariesListLoading
  );
  const isLoadingProjects = useSelector(projectsSelectors.projectsListLoading);
  const isLoadingUsers = useSelector(usersSelectors.usersLoading);
  const token = useSelector(rootUserSelectors.token);
  const usersList = useSelector(usersSelectors.usersList);
  const classes = useStyles();
  const dispatch = useDispatch();

  // Get data from API
  useEffect(() => {
    if (token) {
      dispatch(projectsActions.getProjectsList());
      dispatch(usersActions.getUsersList());
    }
  }, [token]);

  // Convert Users list
  useEffect(() => {
    if (usersList) {
      const newList = convertDataForSelect(usersList);

      setConvertedUsersList(newList);
    }
  }, [usersList]);

  // Convert Projects list
  useEffect(() => {
    if (projectsList) {
      const newList = convertDataForSelect(projectsList);

      setConvertedProjectsList(newList);
    }
  }, [projectsList]);

  // Set selected summary data
  useEffect(() => {
    const selectedSummary =
      summariesList &&
      summariesList.find((summary) => summary.id === selectedSummaryId);

    setSelectedSummaryData(selectedSummary);
    setEditableSelectedSummaryData(selectedSummary);
  }, [
    selectedSummaryId,
    setEditableSelectedSummaryData,
    setSelectedSummaryData,
    summariesList,
  ]);

  // Clear selected summary data
  useEffect(() => {
    if (!isOpenModal) {
      selectedSummaryData && setSelectedSummaryData(undefined);
    }
  }, [isOpenModal, selectedSummaryData, setSelectedSummaryData]);

  // Set filtered selected data
  useEffect(() => {
    const data = isEditModal ? summaryEditableData : summaryPreviewData;

    data && setFilteredSelectedSummaryData(data);
  }, [
    isEditModal,
    setFilteredSelectedSummaryData,
    summaryEditableData,
    summaryPreviewData,
  ]);

  // Set User details
  useEffect(() => {
    const userDetails = usersList?.find((user) => user.email === rootUserEmail);

    userDetails && dispatch(rootUserSliceActions.setUser(userDetails));
  }, [dispatch, usersList]);

  // Set modal title
  useEffect(() => {
    const title = isEditModal ? text.titleEdit : text.titleSummary;

    setModalTitle(title);
    selectedSummaryData &&
      setEditableSelectedSummaryData(
        isEditModal ? selectedSummaryData : undefined
      );
  }, [isEditModal, selectedSummaryData, setEditableSelectedSummaryData]);

  const handleCloseModal = useCallback(() => {
    dispatch(modalSliceActions.toggleOpenModal(false));
  }, [dispatch]);

  const handleEditModal = useCallback(
    (state = true) => {
      dispatch(modalSliceActions.toggleEditModal(state));
    },
    [dispatch, selectedSummaryData, setEditableSelectedSummaryData]
  );

  useEffect(() => {
    if (!initFilter) {
      dispatch(
        summariesActions.getSummaryList(0, rowsPerPage, ordering, filtersList)
      );
    }
  }, [filtersList]);

  const handleSaveEdit = useCallback(() => {
    const convertedSummary =
      editableSelectedSummaryData &&
      convertSummaryToPayload(editableSelectedSummaryData);
    selectedSummaryId &&
      convertedSummary &&
      dispatch(
        summariesActions.updateSummary(selectedSummaryId, convertedSummary)
      );
    dispatch(modalSliceActions.toggleEditModal(false));
  }, [dispatch, editableSelectedSummaryData, selectedSummaryId]);

  const handleRemoveSummary = useCallback(() => {
    if (selectedSummaryId) {
      dispatch(summariesActions.removeSummary(selectedSummaryId));
      dispatch(modalSliceActions.toggleOpenModal(false));
    }
  }, [dispatch, selectedSummaryId]);

  const handleChangeEditableSummaryData = useCallback(
    (e) => {
      const value = e.target.value;
      const selectName: SelectNames = e.target.name;

      editableSelectedSummaryData &&
        setEditableSelectedSummaryData((prevState) => {
          const changedSummaryData = prevState && { ...prevState };

          changedSummaryData && (changedSummaryData[selectName] = value);
          return changedSummaryData;
        });
    },
    [editableSelectedSummaryData, setEditableSelectedSummaryData]
  );

  const handleChangeDate = useCallback(
    (date: MaterialUiPickersDate) => {
      editableSelectedSummaryData &&
        setEditableSelectedSummaryData((prevState) => {
          const changedSummaryData = prevState && { ...prevState };

          changedSummaryData && (changedSummaryData.date = date as Date);
          return changedSummaryData;
        });
    },
    [editableSelectedSummaryData, setEditableSelectedSummaryData]
  );

  // Set data for preview and edit modal
  useEffect(() => {
    const summaryPreview: ModalSummaryItem[] = [];
    const summaryEditable: ModalSummaryItem[] = [];

    selectedSummaryData &&
      ((Object.keys(
        selectedSummaryData
      ) as unknown) as (keyof Summary)[]).forEach((element) => {
        const item: ModalSummaryItem = { subtitle: text[element] };

        // Summary Preview
        if (summaryPreviewElements.indexOf(element) !== -1) {
          typeof selectedSummaryData[element] === "string" &&
            (item.text = selectedSummaryData[element] as string);

          if (element === DATA_ELEMENTS.USER_ID) {
            const user =
              usersList &&
              usersList.find((usr) => usr.id === selectedSummaryData[element]);

            item.text = user?.name || "-";
          }

          summaryPreview.push(item);
        }

        // Summary editable
        if (
          editableSelectedSummaryData &&
          summaryEditableElements.indexOf(element) !== -1
        ) {
          typeof selectedSummaryData[element] === "number" &&
            (item.id = selectedSummaryData[element] as number);
          summaryEditable.push(item);

          item.name = element;
          item.value = editableSelectedSummaryData[element];
          item.type = elementsFieldType[element];

          if (elementsFieldType[element] === INPUT_TYPE.SELECT) {
            let convertedList;

            item.onChange = handleChangeEditableSummaryData;

            if (element === DATA_ELEMENTS.USER_ID) {
              convertedList = usersList ? convertDataForSelect(usersList) : [];
            } else if (element === DATA_ELEMENTS.PROJECT_ID) {
              convertedList = projectsList
                ? convertDataForSelect(projectsList)
                : [];
            }

            item.list = convertedList || [];
          } else if (elementsFieldType[element] === INPUT_TYPE.DATE) {
            item.onChangeDate = handleChangeDate;
          } else if (elementsFieldType[element] === INPUT_TYPE.MULTI_TEXT) {
            item.onChange = handleChangeEditableSummaryData;
          }
        }
      });

    setSummaryPreviewData(summaryPreview);
    setSummaryEditableData(summaryEditable);
  }, [
    editableSelectedSummaryData,
    selectedSummaryData,
    setSummaryEditableData,
    setSummaryPreviewData,
  ]);

  const handleFilter = useCallback(
    (e, type) => {
      const { name } = e.target;

      if (type === INPUT_TYPE.CHECKBOX) {
        const filter = { name, value: e.target.checked };

        dispatch(filtersSliceActions.setFilter(filter));
      } else if (type === INPUT_TYPE.SELECT) {
        const filter = { name, value: e.target.value };

        dispatch(filtersSliceActions.setFilter(filter));
      }
    },
    [dispatch]
  );

  const handleFilterDate = useCallback(
    (date, name) => {
      const filter = { name, value: date.toISOString().split("T")[0] };
      dispatch(filtersSliceActions.setFilter(filter));
    },
    [dispatch]
  );

  useEffect(() => {
    const filterList = filtersList && [
      {
        type: INPUT_TYPE.DATE,
        name: FILTERS.DATE_FROM,
        value:
          filtersList[FILTERS.DATE_FROM] ||
          new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
            .toISOString()
            .split("T")[0], // 7 days before today
        onChangeDate: handleFilterDate,
      },
      {
        type: INPUT_TYPE.DATE,
        name: FILTERS.DATE_TO,
        value:
          filtersList[FILTERS.DATE_TO] ||
          new Date().toISOString().split("T")[0],
        onChangeDate: handleFilterDate,
      },
      {
        type: INPUT_TYPE.SELECT,
        name: FILTERS.USER_ID,
        list: convertedUsersList,
        value: filtersList[FILTERS.USER_ID] || 0,
        onChange: (e: ChangeEvent<{ name?: string; value: unknown }>) =>
          handleFilter(e, INPUT_TYPE.SELECT),
        textAll: text.allUsers,
      },
      {
        type: INPUT_TYPE.SELECT,
        name: FILTERS.PROJECT_ID,
        list: convertedProjectsList,
        value: filtersList[FILTERS.PROJECT_ID] || 0,
        onChange: (e: ChangeEvent<{ name?: string; value: unknown }>) =>
          handleFilter(e, INPUT_TYPE.SELECT),
        textAll: text.allProjects,
      },
      {
        type: INPUT_TYPE.CHECKBOX,
        name: FILTERS.ISSUE,
        checked: filtersList[FILTERS.ISSUE] || false,
        label: text.checkboxIssues,
        onChange: (e: ChangeEvent<{ name?: string; value: unknown }>) =>
          handleFilter(e, INPUT_TYPE.CHECKBOX),
      },
      {
        type: INPUT_TYPE.CHECKBOX,
        name: FILTERS.PLANS,
        checked: filtersList[FILTERS.PLANS] || false,
        label: text.checkboxNoPlans,
        onChange: (e: ChangeEvent<{ name?: string; value: unknown }>) =>
          handleFilter(e, INPUT_TYPE.CHECKBOX),
      },
    ];

    filtersList && setFilters(filterList);
  }, [convertedProjectsList, convertedUsersList, filtersList]);

  const handleChangePage = useCallback(
    (event, newPage) => {
      setPage(newPage);
      dispatch(
        summariesActions.getSummaryList(
          newPage,
          rowsPerPage,
          ordering,
          filtersList
        )
      );
    },
    [dispatch, rowsPerPage, ordering, filtersList]
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      const newRowsPerPage = event.target.value;
      setRowsPerPage(newRowsPerPage);
      dispatch(
        summariesActions.getSummaryList(
          0,
          newRowsPerPage,
          ordering,
          filtersList
        )
      );
    },
    [dispatch, ordering, filtersList]
  );

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc =
        ordering.column === property && ordering.order === ORDER_TYPE.ASC;
      const newOrdering = {
        column: property,
        order: isAsc ? ORDER_TYPE.DESC : ORDER_TYPE.ASC,
      };
      setOrdering(newOrdering);
      dispatch(
        summariesActions.getSummaryList(
          0,
          rowsPerPage,
          newOrdering,
          filtersList
        )
      );
    },
    [dispatch, ordering, rowsPerPage, filtersList]
  );

  useEffect(() => {
    if (initFilter && filters) {
      filters.forEach((filter) => {
        switch (filter.type) {
          case INPUT_TYPE.CHECKBOX:
            return dispatch(
              filtersSliceActions.setFilter({
                name: filter.name,
                value: filter.checked as boolean,
              })
            );
          default:
            return dispatch(
              filtersSliceActions.setFilter({
                name: filter.name,
                value: filter.value,
              })
            );
        }
      });
      setInitFilter(false);
    }
  }, [filters, initFilter, setInitFilter]);

  return (
    <main className={classes.root}>
      <div className={classes.dashboard}>
        {/* Filters */}
        {filters && <FiltersBar filters={filters} />}
        {/* Table */}
        {isLoadingSummaries || isLoadingProjects || isLoadingUsers ? (
          <Loader />
        ) : (
          <>
            {summariesList?.length ? (
              <>
                <EnhancedTable
                  data={summariesList}
                  ordering={ordering}
                  handleRequestSort={handleRequestSort}
                />
                {/* Modal */}
                <Modal
                  isOpen={isOpenModal}
                  onClose={handleCloseModal}
                  title={modalTitle}
                >
                  {isEditModal && editableSelectedSummaryData ? (
                    <ModalEditableSummary
                      data={filteredSelectedSummaryData}
                      onCloseEdit={() => handleEditModal(false)}
                      onRemoveSummary={handleRemoveSummary}
                      onSaveChanges={handleSaveEdit}
                    />
                  ) : (
                    <ModalSummary
                      data={filteredSelectedSummaryData}
                      onEdit={handleEditModal}
                    />
                  )}
                </Modal>
                <Paper className={classes.pagination}>
                  <PaginationBar
                    count={summariesCount}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 15, 25, 50]}
                  />
                </Paper>
              </>
            ) : (
              <AlertMessage severity={SEVERITY.INFO} text={text.noData} />
            )}
          </>
        )}
      </div>
    </main>
  );
};

export default Dashboard;
