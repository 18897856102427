import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ROUTES from "../../utils/routes";
import { AuthCheckProps } from "./types";
import { useDispatch, useSelector } from "react-redux";
import rootUserSelectors from "../../../redux/rootUser/selectors";
import rootUserActions from "../../../redux/rootUser/action";
import { COOKIE } from "../../utils/types";
import { getCookie } from "../../utils/cookie";
import { rootUserSliceActions } from "../../../redux/rootUser/reducer";

const AuthCheck = ({ children }: AuthCheckProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuth = useSelector(rootUserSelectors.isAuth);
  const loaded = useSelector(rootUserSelectors.loaded);

  useEffect(() => {
    const token = getCookie(COOKIE.TOKEN);

    if (token) {
      dispatch(rootUserActions.me());
    } else {
      dispatch(rootUserSliceActions.setLoaded());
    }
  }, [dispatch]);

  useEffect(() => {
    const route = isAuth ? ROUTES.DASHBOARD : ROUTES.LOGIN;

    history.push(route);
  }, [history, isAuth]);

  return <>{loaded && children}</>;
};

export default AuthCheck;
