import { DATE_VARIANT, INPUT_VARIANT } from "../../../utils/types";
import { DateFieldProps } from "./types";
import useStyles from "./styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { DATE_FORMAT } from "../../../../config";
import { replaceSpaceToDash } from "../../../utils/functions";
import classNames from "classnames";
import { useCallback } from "react";

const DateField = ({
  fullWidth = false,
  id,
  name,
  onChange,
  value = new Date(),
}: DateFieldProps) => {
  const classes = useStyles();
  const fieldId: string = replaceSpaceToDash(id);

  const handeChange = useCallback((e) => {
    onChange(e, name);
  }, []);

  return (
    <KeyboardDatePicker
      className={classNames(classes.dateField, {
        [classes.fullWidth]: fullWidth,
      })}
      format={DATE_FORMAT}
      id={fieldId}
      inputVariant={INPUT_VARIANT.OUTLINED}
      name={name}
      onChange={handeChange}
      size={"small"}
      variant={DATE_VARIANT.INLINE}
      value={value}
      KeyboardButtonProps={{
        "aria-label": "change date",
        disableRipple: true,
      }}
    />
  );
};

export default DateField;
