import { createStyles, makeStyles } from "@material-ui/core/styles";
import { FONT } from "../../../utils/font";
import COLOR from "../../../utils/color";

const useStyles = makeStyles(() =>
  createStyles({
    buttonsContainer: {
      flexDirection: "column",
      justifyContent: "center",
      marginTop: "auto",
      padding: "8px 0",

      "& > :not(:first-child)": {
        marginLeft: 0,
      },

      "& button:not(:first-child)": {
        margin: "0 0 20px",
      },

      "& button:last-element": {
        marginBottom: 0,
      },
    },

    buttonsWrapper: {
      display: "flex",
      justifyContent: "center",
      width: "100%",

      "& button": {
        marginBottom: 20,
        minWidth: 150,
        width: "48%",

        "&:not(:first-child)": {
          marginLeft: "4%",
        },
      },
    },

    oneButtonWrapper: {
      "& .MuiButton-root": {
        marginBottom: 0,
      },
    },

    row: {
      paddingBottom: 20,
    },

    rowsContainer: {
      "&.MuiDialogContent-root": {
        display: "flex",
        flexDirection: "column",
        margin: 0,
        padding: "10px 0",
      },
    },

    subtitle: {
      "&.MuiTypography-root": {
        ...FONT.SMALL,
        color: COLOR.GREY_LIGHT_4,
        fontWeight: 600,
        paddingBottom: 5,
        paddingLeft: 16,
      },
    },
  })
);

export default useStyles;
