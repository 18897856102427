const COLOR = {
  BLACK: "#13101B",
  BLACK_TRANSPARENCY: "rgba(19,20,21,0.2)",
  GREEN: "#C4CC58",
  GREY: "#5B5B5B",
  GREY_2: "#777777",
  GREY_LIGHT: "#EEEFEA",
  GREY_LIGHT_2: "#D4CACA",
  GREY_LIGHT_3: "#F9F8F7",
  GREY_LIGHT_4: "rgb(102,81,51, 0.6)",
  GREY_LIGHT_5: "rgba(255,255,255,0.1)",
  GREY_LIGHT_6: "rgba(255,255,255,0.2)",
  ORANGE: "#E4A044",
  RED: "#E95454",
  TRANSPARENT: "transparent",
  WHITE: "#FFFFFF",
  YELLOW: "#EFFF04",

  // TODO: TO remove in the future
  // primary: "#E4A044",
  // blue: "#61dafb",
  // yellow: "#e5d569",
};

export default COLOR;
