import { createStyles, makeStyles } from "@material-ui/core/styles";
import { FONT } from "../../../utils/font";
import COLOR from "../../../utils/color";

export const useStyles = makeStyles(() => {
  return createStyles({
    button: {
      ...FONT.PARAGRAPH,
      backgroundColor: COLOR.TRANSPARENT,
      borderShadow: "none",
      boxShadow: "none",
      color: COLOR.GREY,
      fontWeight: 600,
      padding: 10,
      textTransform: "none",

      "&:hover": {
        backgroundColor: COLOR.TRANSPARENT,
        borderShadow: "none",
        boxShadow: "none",
      },
    },
    buttonBrand: {
      backgroundColor: COLOR.BLACK,
      borderShadow: "none",
      boxShadow: "0 10px 26px 0 rgba(100,51,102,0.17)",
      color: COLOR.YELLOW,

      "&:hover": {
        backgroundColor: COLOR.BLACK,
        borderShadow: "none",
        boxShadow: "none",
        color: COLOR.YELLOW,
      },
    },
    buttonTransparent: {
      backgroundColor: COLOR.TRANSPARENT,
      color: COLOR.GREY,

      "&:hover": {
        backgroundColor: COLOR.TRANSPARENT,
        borderShadow: "none",
        boxShadow: "none",
      },
    },
  });
});
