import { User, UsersListError, UsersListLoading, UsersState } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: UsersState = {
  list: undefined,
  isLoading: false,
  error: undefined,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsersList: (state, action: PayloadAction<User[]>) => {
      state.list = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    setUsersListError: (state, action: PayloadAction<UsersListError>) => {
      state.list = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    setUsersListLoading: (state, action: PayloadAction<UsersListLoading>) => {
      state.isLoading = action.payload;
      state.error = undefined;
    },
  },
});

export const usersSliceActions = usersSlice.actions;

export default usersSlice.reducer;
