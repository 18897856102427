import MaterialButton from "@material-ui/core/Button";
import { useStyles } from "./styles";
import classNames from "classnames";
import React from "react";
import { OG_TYPE } from "../../../utils/types";
import { ButtonProps, OgImgProps } from "./types";

const ogImg: OgImgProps = {
  alt: "Order Group",
  src: "static/images/logo_black.svg",
};

const Button = (props: ButtonProps) => {
  const {
    fullWidth = false,
    ogType = OG_TYPE.TRANSPARENT,
    text,
    type,
    variant,
  } = props;
  const classes = useStyles();

  return (
    <MaterialButton
      className={classNames(classes.root, classes[ogType])}
      disableRipple
      fullWidth={fullWidth}
      type={type}
      variant={variant}
    >
      <span className={classes.text}>{text}</span>
      {ogType === OG_TYPE.BRAND && (
        <div className={classes.logoWrapper}>
          <img src={ogImg.src} alt={ogImg.alt} className={classes.logo} />
        </div>
      )}
    </MaterialButton>
  );
};

export default Button;
