import { RootState } from "../types";
import { EditModal, OpenModal, SelectedSummaryId } from "./types";

const isEditModal = (state: RootState): EditModal => state.modal.isEdit;

const isOpenModal = (state: RootState): OpenModal => state.modal.isOpen;

const selectedSummaryId = (state: RootState): SelectedSummaryId =>
  state.modal.selectedSummaryId;

const modalSelectors = {
  isEditModal,
  isOpenModal,
  selectedSummaryId,
};

export default modalSelectors;
