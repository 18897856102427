import React from "react";
import { TableHead } from "@mui/material";
import TableRow from "../TableRow";
import { CELL_TYPE, EnhancedTableHeadProps } from "../types";

const EnhancedTableHead = ({
  list,
  onRequestSort,
  order,
  orderBy,
}: EnhancedTableHeadProps) => {
  return (
    <TableHead>
      <TableRow
        onRequestSort={onRequestSort}
        list={list}
        type={CELL_TYPE.HEADER}
        order={order}
        orderBy={orderBy}
      />
    </TableHead>
  );
};

export default EnhancedTableHead;
