import { createStyles, makeStyles } from "@material-ui/core/styles";
import { FONT } from "../../utils/font";
import COLOR from "../../utils/color";

export const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      marginTop: 10,

      "& label": {
        ...FONT.MEDIUM,
      },
      "& label.Mui-focused": {
        ...FONT.MEDIUM,
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: COLOR.BLACK,
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: COLOR.WHITE,
        },
        "&:hover fieldset": {
          borderColor: COLOR.WHITE,
        },
        "&.Mui-focused fieldset": {
          borderColor: COLOR.BLACK,
        },
      },

      "& .MuiFormHelperText-root.Mui-error": {
        ...FONT.SMALL,
        fontWeight: 700,
        color: COLOR.RED,
      },
    },
    input: {
      ...FONT.MEDIUM,
      background: COLOR.TRANSPARENT,
      padding: "5px 0",
      maxWidth: 500,

      "&:-internal-autofill-selected": {
        backgroundColor: `${COLOR.TRANSPARENT} !important`,
        color: COLOR.BLACK,
      },
    },
    inputFocused: {
      background: COLOR.TRANSPARENT,
    },
  });
});
