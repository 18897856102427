import React, { useCallback, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../Logo";
import { IconButton, Typography } from "@material-ui/core";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import classNames from "classnames";
import { TextProps } from "./types";
import { ICON_SIZE, POSITION_TYPE } from "../../utils/types";
import rootUserSelectors from "../../../redux/rootUser/selectors";
import { useHistory } from "react-router-dom";
import ROUTES from "../../utils/routes";
import rootUserActions from "../../../redux/rootUser/action";

const text: TextProps = {
  iconLabel: "Logout",
  iconSize: ICON_SIZE.SMALL,
  position: {
    static: POSITION_TYPE.STATIC,
    fixed: POSITION_TYPE.FIXED,
  },
};

const Navbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuth = useSelector(rootUserSelectors.isAuth);
  const rootUserEmail = useSelector(rootUserSelectors.rootUserEmail);

  useEffect(() => {
    !isAuth && history.push(ROUTES.LOGIN);
  }, [history, isAuth]);

  const handleLogout = useCallback(() => {
    dispatch(rootUserActions.logout());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <AppBar
        position={isAuth ? text.position.static : text.position.fixed}
        className={classNames(classes.navbar, {
          [classes.navbarLogged]: isAuth,
        })}
      >
        <Toolbar>
          {/* Logo */}
          <div className={classes.logo}>
            <Logo />
          </div>
          {/* Nav with auth */}
          {isAuth && (
            <>
              {/* User name */}
              <Typography className={classes.text}>{rootUserEmail}</Typography>
              {/* Logout button */}
              <IconButton
                className={classes.button}
                aria-label={text.iconLabel}
                size={text.iconSize}
                onClick={handleLogout}
              >
                <MeetingRoomIcon className={classes.iconLogout} />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
