import {
  SummariesListError,
  SummariesListLoading,
  SummariesState,
  SummaryPayload,
} from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: SummariesState = {
  list: undefined,
  count: 0,
  isLoading: false,
  error: undefined,
};

const summariesSlice = createSlice({
  name: "summaries",
  initialState,
  reducers: {
    setSummariesList: (state, action: PayloadAction<SummaryPayload>) => {
      state.count = action.payload.count;
      state.list = action.payload.summaries;
      state.isLoading = false;
      state.error = undefined;
    },
    setSummariesListError: (
      state,
      action: PayloadAction<SummariesListError>
    ) => {
      state.count = 0;
      state.list = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    setSummariesListLoading: (
      state,
      action: PayloadAction<SummariesListLoading>
    ) => {
      state.isLoading = action.payload;
      state.error = undefined;
    },
  },
});

export const summariesSliceActions = summariesSlice.actions;

export default summariesSlice.reducer;
