export enum FILTERS {
  DATE_FROM = "DATE_FROM",
  DATE_TO = "DATE_TO",
  USER_ID = "USER_ID",
  PROJECT_ID = "PROJECT_ID",
  ISSUE = "ISSUE",
  PLANS = "PLANS",
}

export interface FiltersState {
  [FILTERS.DATE_FROM]?: Date;
  [FILTERS.DATE_TO]?: Date;
  [FILTERS.USER_ID]?: number;
  [FILTERS.PROJECT_ID]?: number;
  [FILTERS.ISSUE]?: boolean;
  [FILTERS.PLANS]?: boolean;
}

export type FiltersType =
  | FILTERS.DATE_FROM
  | FILTERS.DATE_TO
  | FILTERS.USER_ID
  | FILTERS.PROJECT_ID
  | FILTERS.PLANS
  | FILTERS.ISSUE;

export type Filter = {
  name: FILTERS;
  value: any; // string | number | boolean | Date
};
