import React, { useCallback, useEffect, useState } from "react";
import { Table, TableContainer } from "@mui/material";
import EnhancedTableHead from "../EnhancedTableHead";
import TableBody from "../TableBody";
import {
  ALIGN_TYPE,
  CellWithButtonType,
  Data,
  DATA_TYPE,
  HeadCell,
  ICONS_TYPE,
} from "../types";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { columnsOrder, headCells, icons, iconsByColumnType } from "./config";
import summariesSelectors from "../../../../redux/summaries/selectors";
import usersSelectors from "../../../../redux/users/selectors";
import projectsSelectors from "../../../../redux/projects/selectors";
import { modalSliceActions } from "../../../../redux/modal/reducer";
import { SEVERITY } from "../../../utils/types";
import AlertMessage from "../../AlertMessage";
import { EnhancedTableProps } from "./types";
import { Summary } from "../../../../redux/summaries/types";

const EnhancedTable = ({
  data,
  ordering,
  handleRequestSort,
}: EnhancedTableProps) => {
  const [bodyRowsList, setBodyRowsList] = useState<any>();
  const [styledBodyRowsList, setStyledBodyRowsList] = useState<any>();
  const summariesListError = useSelector(summariesSelectors.summariesListError);
  const usersList = useSelector(usersSelectors.usersList);
  const projectsList = useSelector(projectsSelectors.projectsList);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleShowSummary = useCallback(
    (id, editable = false) => {
      dispatch(modalSliceActions.setSelectedSummaryId(id));
      editable
        ? dispatch(modalSliceActions.toggleEditModal(true))
        : dispatch(modalSliceActions.toggleOpenModal(true));
    },
    [dispatch, data]
  );

  const createBodyRowData = useCallback(
    (id, date, projectId, summary, plans, issue, edit, userId) => {
      const user = usersList && usersList.find((usr) => usr.id === userId);
      const project =
        projectsList && projectsList.find((prj) => prj.id === projectId);
      const data = {
        [DATA_TYPE.SUMMARY_ID]: id,
        [DATA_TYPE.NAME]: user?.name || "-",
        [DATA_TYPE.DATE]: new Date(date).toISOString().split("T")[0],
        [DATA_TYPE.PROJECTS]: project?.name || "-",
        [DATA_TYPE.SUMMARY]: Boolean(summary),
        [DATA_TYPE.PLANS]: Boolean(plans),
        [DATA_TYPE.ISSUE]: Boolean(issue),
        [DATA_TYPE.EDIT]: edit,
      };

      return data;
    },
    [projectsList, usersList]
  );

  // Set body rows list
  useEffect(() => {
    const bodyRows =
      data &&
      data.map((item: Summary) => {
        const {
          id,
          date,
          projectId,
          summary,
          plans,
          issue,
          edit,
          userId,
        } = item;

        return createBodyRowData(
          id,
          date,
          projectId,
          summary,
          plans,
          issue,
          edit,
          userId
        );
      });

    setBodyRowsList(bodyRows);
  }, [usersList, data]);

  // Set styled body rows list
  useEffect(() => {
    const sortedBodyRows =
      bodyRowsList?.length &&
      bodyRowsList.map((listItems: Data) => {
        return columnsOrder.map((column: keyof typeof DATA_TYPE) => {
          const alignType = headCells.find((cell) => cell.id === column);
          const align = alignType ? alignType.align : ALIGN_TYPE.LEFT;
          const cellData: HeadCell = {
            align,
            id: column,
          };

          if (typeof listItems[column] === "boolean") {
            const checkStatus = listItems[column];

            cellData.check = checkStatus;
            cellData.icon =
              iconsByColumnType[column as CellWithButtonType][
                checkStatus ? "positive" : "negative"
              ];
            cellData.disabled = cellData.icon === icons[ICONS_TYPE.NONE];
            cellData.onClick = () =>
              handleShowSummary(
                listItems[DATA_TYPE.SUMMARY_ID],
                column === DATA_TYPE.EDIT
              );
          }

          if (typeof listItems[column] === "string") {
            const checkStatus = listItems[column];

            column === DATA_TYPE.NAME &&
              (cellData.avatar =
                iconsByColumnType[column as DATA_TYPE.NAME].avatar);
            cellData.label = checkStatus;
          }

          return cellData;
        });
      });
    setStyledBodyRowsList(sortedBodyRows);
  }, [bodyRowsList]);

  return (
    <>
      {!summariesListError && (
        <TableContainer className={classes.root}>
          <Table
            aria-labelledby="OG Daily"
            size={"medium"}
            aria-label={"OG Daily"}
          >
            <EnhancedTableHead
              list={headCells}
              order={ordering.order}
              orderBy={ordering.column}
              onRequestSort={handleRequestSort}
            />
            <TableBody
              list={styledBodyRowsList}
              order={ordering.order}
              orderBy={ordering.column}
            />
          </Table>
        </TableContainer>
      )}
      {summariesListError && (
        <AlertMessage severity={SEVERITY.ERROR} text={summariesListError} />
      )}
    </>
  );
};

export default EnhancedTable;
