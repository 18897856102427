import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoginPage from "./containers/LoginPage";
import Dashboard from "./containers/Dashboard";
import Navbar from "./components/Navbar";
import ROUTES from "./utils/routes";
import AuthCheck from "./components/AuthCheck";
import { useStyles } from "./styles";

const App: React.FC = () => {
  const classes = useStyles();

  return (
    <BrowserRouter>
      <AuthCheck>
        <div className={classes.root}>
          <Navbar />
          <Switch>
            <Route path={ROUTES.DASHBOARD} component={Dashboard} />
            <Route path={ROUTES.LOGIN} component={LoginPage} />
          </Switch>
        </div>
      </AuthCheck>
    </BrowserRouter>
  );
};

export default App;
