import TextField from "@material-ui/core/TextField";
import { useStyles } from "./styles";
import { INPUT_TYPE, INPUT_VARIANT } from "../../utils/types";
import { FormFieldProps } from "./types";

const FormField = (props: FormFieldProps) => {
  const {
    error,
    fullWidth = false,
    helperText = "",
    id,
    label,
    name,
    onChange,
    type = INPUT_TYPE.TEXT,
    value,
    variant = INPUT_VARIANT.OUTLINED,
  } = props;
  const classes = useStyles();

  return (
    <TextField
      className={classes.root}
      error={error}
      fullWidth={fullWidth}
      helperText={helperText}
      id={id}
      label={label}
      name={name}
      onChange={onChange}
      type={type}
      value={value}
      variant={variant}
      InputProps={{
        className: classes.root,
        classes: {
          root: classes.input,
          focused: classes.inputFocused,
        },
      }}
    />
  );
};

export default FormField;
