import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import filters from "./filters/reducer";
import modal from "./modal/reducer";
import projects from "./projects/reducer";
import rootUser from "./rootUser/reducer";
import summaries from "./summaries/reducer";
import users from "./users/reducer";
import { createLogger } from "redux-logger";

const logger = createLogger({
  collapsed: true,
});

const composedEnhancer = composeWithDevTools(applyMiddleware(thunk, logger));

const rootReducer = combineReducers({
  rootUser,
  filters,
  modal,
  projects,
  summaries,
  users,
});

export function configureStore() {
  return createStore(rootReducer, composedEnhancer);
}
