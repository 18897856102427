import axios from "axios";
import { API_URL } from "../config";
import { getCookie } from "../app/utils/cookie";
import { COOKIE } from "../app/utils/types";

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use((request) => {
  const token = getCookie(COOKIE.TOKEN) || "";

  request.headers.common.Authorization = `token ${token}`;

  return request;
});

export default axiosInstance;
