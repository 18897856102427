import { Project } from "./types";
import { PROJECTS_URLS } from "./constants";
import { AxiosPromise } from "axios";
import axiosInstance from "../axiosInstance";

const getProjectsList = (): AxiosPromise<Project[]> => {
  const url: string = PROJECTS_URLS.projectsListUrl;

  return axiosInstance({
    url,
    method: "GET",
  });
};

export const projectsServices = {
  getProjectsList,
};
