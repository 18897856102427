import { projectsSliceActions } from "./reducer";
import { Project, ProjectsListError, ProjectsListLoading } from "./types";
import { RootState } from "../types";
import { PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { projectsServices } from "./services";

const getProjectsList = (): ThunkAction<
  void,
  RootState,
  unknown,
  PayloadAction<Project[] | ProjectsListError | ProjectsListLoading>
> => {
  return (dispatch) => {
    dispatch(projectsSliceActions.setProjectsListLoading(true));
    projectsServices
      .getProjectsList()
      .then((data) => data?.data)
      .then((response: Project[]) => {
        dispatch(projectsSliceActions.setProjectsList(response));
      })
      .catch((err) => {
        if (err.response) {
          console.error(err.response);
        } else if (err.request) {
          console.error(err.request);
        } else {
          console.error(`Error: ${err.message}`);
        }
        console.error(err.config);
        dispatch(projectsSliceActions.setProjectsListError(err.message));
      });
  };
};

const projectsActions = {
  ...projectsSliceActions,
  getProjectsList,
};

export default projectsActions;
