import { ORDER_TYPE } from "../app/components/table/types";
import { FILTERS } from "./filters/types";
import { UrlCreatorProps } from "./types";

const FILTER_STRING_LENGTH = 1;

const columnsUrlMapper = {
  SUMMARY_ID: "id",
  NAME: "app_user__name",
  DATE: "reported_date",
  PROJECTS: "project__name",
  SUMMARY: "reported_progress",
  PLANS: "plan",
  ISSUE: "problem",
  EDIT: "edit",
};

const filtersUrlMapper = {
  DATE_FROM: "reported_date__gte",
  DATE_TO: "reported_date__lte",
  USER_ID: "app_user__id",
  PROJECT_ID: "project__id",
  ISSUE: "problem__gt",
  PLANS: "plan__lt",
};

export const createURL = ({
  base,
  page,
  records,
  ordering,
  filters,
}: UrlCreatorProps): string => {
  let url = base;
  const params = new URLSearchParams();

  // filters
  if (filters) {
    params.append(
      filtersUrlMapper[FILTERS.DATE_FROM],
      filters[FILTERS.DATE_FROM]?.toString() || ""
    );
    params.append(
      filtersUrlMapper[FILTERS.DATE_TO],
      filters[FILTERS.DATE_TO]?.toString() || ""
    );
    if (filters[FILTERS.ISSUE]) {
      params.append(
        filtersUrlMapper[FILTERS.ISSUE],
        FILTER_STRING_LENGTH.toString()
      );
    }
    if (filters[FILTERS.PLANS]) {
      params.append(
        filtersUrlMapper[FILTERS.PLANS],
        FILTER_STRING_LENGTH.toString()
      );
    }
    if (filters[FILTERS.USER_ID]) {
      params.append(
        filtersUrlMapper[FILTERS.USER_ID],
        filters[FILTERS.USER_ID]?.toString() || ""
      );
    }
    if (filters[FILTERS.PROJECT_ID]) {
      params.append(
        filtersUrlMapper[FILTERS.PROJECT_ID],
        filters[FILTERS.PROJECT_ID]?.toString() || ""
      );
    }
  }

  // pagination
  if (typeof page !== "undefined") {
    params.append("page", (page + 1).toString());
  }
  if (records) {
    params.append("records", records.toString());
  }

  // ordering
  if (ordering) {
    const orderingStr =
      ordering.order === ORDER_TYPE.DESC
        ? "-" + columnsUrlMapper[ordering.column]
        : columnsUrlMapper[ordering.column];
    params.append("ordering", orderingStr);
  }

  if (params.toString()) {
    url += "?" + params.toString();
  }

  return url;
};
