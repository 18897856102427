import * as yup from "yup";
import { SchemaProps } from "./types";

const text: SchemaProps = {
  valEmail: "Wprowadź prawidłowy adres email",
  valEmailReq: "Adres email jest wymagany",
  valPass: "Hasło powinno składać się z minimum 8 znaków",
  valPassReq: "Hasło jest wymagane",
};

export const validationSchema = yup.object({
  email: yup.string().email(text.valEmail).required(text.valEmailReq),
  password: yup.string().min(8, text.valPass).required(text.valPassReq),
});
