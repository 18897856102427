import React, { Fragment } from "react";
import { TableBody as MaterialTableBody } from "@mui/material";
import TableRow from "../TableRow";
import { CELL_TYPE, HeadCell, TableBodyProps } from "../types";

const TableBody = ({ list, order, orderBy }: TableBodyProps) => {
  return (
    <MaterialTableBody>
      {list?.length &&
        list.map((item: HeadCell[], index: number) => {
          return (
            <Fragment key={index}>
              {/* Row */}
              <TableRow
                list={item}
                order={order}
                orderBy={orderBy}
                type={CELL_TYPE.BODY}
              />
              {/* Empty row - need only for styling */}
              <TableRow
                order={order}
                orderBy={orderBy}
                type={CELL_TYPE.BODY}
                colSpan={item?.length}
              />
            </Fragment>
          );
        })}
    </MaterialTableBody>
  );
};

export default TableBody;
