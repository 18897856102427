import {
  Project,
  ProjectsListError,
  ProjectsListLoading,
  ProjectsState,
} from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ProjectsState = {
  list: undefined,
  isLoading: false,
  error: undefined,
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjectsList: (state, action: PayloadAction<Project[]>) => {
      state.list = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    setProjectsListError: (state, action: PayloadAction<ProjectsListError>) => {
      state.list = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    setProjectsListLoading: (
      state,
      action: PayloadAction<ProjectsListLoading>
    ) => {
      state.isLoading = action.payload;
      state.error = undefined;
    },
  },
});

export const projectsSliceActions = projectsSlice.actions;

export default projectsSlice.reducer;
