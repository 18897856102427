import { AxiosPromise } from "axios";
import axiosInstance from "../axiosInstance";
import { User } from "./types";
import { USERS_URLS } from "./constants";

const getUsersList = (): AxiosPromise<User[]> => {
  const url: string = USERS_URLS.usersListUrl;

  return axiosInstance({
    url,
    method: "GET",
  });
};

export const usersServices = {
  getUsersList,
};
