import React from "react";
import { Dialog } from "@material-ui/core";
import useStyles from "./style";
import ModalHeader from "../ModalHeader";
import { DialogContent } from "@mui/material";
import { ModalProps } from "../types";

const Modal = ({ children, isOpen, onClose, title }: ModalProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal"
      className={classes.modal}
    >
      {/* Header */}
      <ModalHeader title={title} handleCloseModal={onClose} />
      {/* Content */}
      <DialogContent className={classes.modalContent}>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
