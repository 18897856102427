import COLOR from "./color";

export const FONT_FAMILY = {
  SPACE_GROTESK: "'Space Grotesk', sans-serif !important",
  WORK_SANS: "'Work Sans', sans-serif !important;",
};

export const FONT = {
  SMALL: {
    color: COLOR.BLACK,
    fontFamily: FONT_FAMILY.SPACE_GROTESK,
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: "-0.02em",
    lineHeight: "12px",
  },
  MEDIUM: {
    color: COLOR.BLACK,
    fontFamily: FONT_FAMILY.SPACE_GROTESK,
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: "-0.02em",
    lineHeight: "32px",
  },
  LARGE: {
    color: COLOR.GREY,
    fontFamily: FONT_FAMILY.SPACE_GROTESK,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "27px",
  },
  X_LARGE: {},
  PARAGRAPH: {
    color: COLOR.BLACK,
    fontFamily: FONT_FAMILY.SPACE_GROTESK,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: "-0.02em",
    lineHeight: "18px",
  },
  SUBTITLE: {
    color: COLOR.BLACK,
    fontFamily: FONT_FAMILY.WORK_SANS,
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: "-0.05em",
    lineHeight: "32px",
  },
  TITLE: {
    color: COLOR.BLACK,
    fontFamily: FONT_FAMILY.SPACE_GROTESK,
    fontSize: 48,
    fontWeight: 500,
    letterSpacing: "-0.05em",
    lineHeight: "52px",
  },
};
