import React from "react";
import LoginForm from "../../components/LoginForm";
import { useStyles } from "./styles";

const LoginPage: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.authContainer}>
      <LoginForm />
    </div>
  );
};

export default LoginPage;
