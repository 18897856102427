import { INPUT_VARIANT } from "../../../utils/types";
import { SelectFieldProps } from "./types";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import useStyles from "./styles";
import { PlayArrowRounded } from "@material-ui/icons";
import { replaceSpaceToDash } from "../../../utils/functions";
import classNames from "classnames";

const SelectField = ({
  fullWidth = false,
  id,
  isMultiple = false,
  name,
  list,
  onChange,
  textAll = "Wszystko",
  value,
}: SelectFieldProps) => {
  const classes = useStyles();
  const fieldId: string = replaceSpaceToDash(id);

  return (
    <FormControl
      className={classNames(classes.formControl, {
        [classes.fullWidth]: fullWidth,
      })}
      variant={INPUT_VARIANT.OUTLINED}
    >
      <Select
        id={fieldId}
        multiple={isMultiple}
        name={name}
        onChange={onChange}
        value={value}
        IconComponent={PlayArrowRounded}
        MenuProps={{
          style: {
            maxHeight: 500,
          },
        }}
      >
        <MenuItem value={0}>{textAll}</MenuItem>
        {list &&
          list.map((item, index: number) => {
            return (
              <MenuItem key={index} value={item?.value}>
                {item?.text || "-"}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default SelectField;
