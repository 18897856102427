import { createStyles, makeStyles } from "@material-ui/core/styles";
import COLOR from "../../../utils/color";

export const useStyles = makeStyles(() => {
  return createStyles({
    disabled: {
      "&.MuiIconButton-root": {
        cursor: "auto",
      },
    },
    root: {
      "&.MuiIconButton-root": {
        padding: 0,

        "&:hover": {
          backgroundColor: COLOR.TRANSPARENT,
        },
      },
    },
  });
});
