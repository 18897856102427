import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      "&.MuiTableRow-root": {
        boxShadow: "0 10px 24px 0 rgba(91,91,91,0.2)",
        borderRadius: 8,
      },
    },
  })
);

export default useStyles;
