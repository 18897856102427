import { FilterData, FiltersBarProps } from "./types";
import CheckboxField from "../fields/CheckboxField";
import useStyles from "./styles";
import { INPUT_TYPE } from "../../utils/types";
import DateField from "../fields/DateField";
import SelectField from "../fields/SelectField";
import { Fragment } from "react";

const FiltersBar = ({ filters }: FiltersBarProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {filters &&
        [...filters].map((filter: FilterData) => {
          switch (filter.type) {
            case INPUT_TYPE.SELECT:
              return (
                filter.onChange &&
                filter.list && (
                  <SelectField
                    id={filter.name}
                    name={filter.name}
                    onChange={filter.onChange}
                    value={filter.value as number}
                    list={filter.list}
                    key={filter.name}
                    textAll={filter.textAll}
                  />
                )
              );
            case INPUT_TYPE.DATE:
              return (
                filter.onChangeDate && (
                  <DateField
                    id={filter.name}
                    name={filter.name}
                    onChange={filter.onChangeDate}
                    value={filter.value as Date}
                    key={filter.name}
                  />
                )
              );
            case INPUT_TYPE.CHECKBOX:
              return (
                filter.onChange &&
                filter.checked !== undefined && (
                  <CheckboxField
                    checked={filter.checked}
                    name={filter.name}
                    onChange={filter.onChange}
                    label={filter.label}
                    key={filter.name}
                  />
                )
              );
            default:
              return (
                <Fragment key={"no-type"}>
                  Missing filter type: {filter.type}
                </Fragment>
              );
          }
        })}
    </div>
  );
};

export default FiltersBar;
